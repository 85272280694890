<template>
    <div>
        <div class="col-lg-12" v-if="isLoadingBlade">
            <div class="d-flex justify-content-center">
                <img :src="loadingBladeUrl" />
            </div>
        </div>
    </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex';
    import swal from 'sweetalert2';// import sweet alert

    export default {
        name: 'VerificationCallback',
        data() {
            return {
                orderId: this.$route.query.orderID,
                isLoadingBlade: true
            }
        },
        computed: {
            ...mapGetters(['loadingBladeUrl'])
        },
        methods: {
            ...mapActions(['saveApplicationTransaction']),
            loadCallback() { 
                this.saveApplicationTransaction(this.orderId).then((res) => {
                    this.isLoadingBlade = false;
                    if(res.status) {
                        swal.fire("Payment Successful!", res.msg, "success");
                        return window.location.href = '/application-form';
                    } else {
                        this.isLoadingBlade = false;
                        swal.fire("Payment Failed!", res.msg, "warning");
                        setTimeout(function() { window.location.href = '/application-generate'; }, 5000);
                    }
                }).
                catch(() => {
                    this.isLoadingBlade = false;
                    let msg = "Network Failure!, kindly reload the page...";
                    swal.fire("Error Ocurred!", msg, "warning");
                    setTimeout(function() { window.location.href = '/application-generate'; }, 5000);
                });
            }
        },
        created() {
            this.loadCallback();
        }
    }
</script>

<style scoped>
.right {
    float: right;
}
</style>