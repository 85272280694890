
<template>
    <div>
        <div class="col-lg-8" v-if="records && records.length > 0">
            <small class="font-weight-bold">
                Showing {{ pagination.from == 0 ? 1 : pagination.from }} - {{ pagination.to }} of
                {{ pagination.total }} entries
            </small>
        </div>

        <div
            class="card shadow"
            v-if="records && records.length > 0"
        >
            <div class="table-responsive">
                <base-table
                    class="table align-items-center table-flush"
                    thead-classes="thead-light"
                    tbody-classes="list"
                    :data="records"
                >
                    <template slot="columns">
                        <th width="2%">S/N</th>
                        <th width="15%">Subject</th>
                        <th width="10%">Date Notified</th>
                        <th width="10%">&nbsp;</th>
                    </template>

                    <template
                        slot-scope="{row, index}"
                    >
                        <td class="budget">
                            {{ pagination.from+index}}
                        </td>
                        <td
                            :class="'text-capitalize '+((row.status == 'unread') ? 'font-weight-bold' : '')"
                        >
                            {{ row.subject }}
                        </td>
                        <td>
                            {{ row.created_at | formatDate }}
                        </td>
                        <td>
                            <router-link
                                :to="'/notifications/'+ row.id"
                                class="btn btn-sm btn-success"
                            >
                                <i class="fa fa-eye"></i> View
                            </router-link>
                            <base-button
                                type="danger" size="sm"
                                @click="removeNotification(row.id)"
                                :disabled="row.type == 'general'"
                            >
                                <i class="fa fa-trash"></i> Delete
                            </base-button>
                        </td>
                    </template>
                </base-table>
            </div>

            <div
                class="card-footer d-flex justify-content-end bg-transparent"
                v-if="hasPagination"
                    @click="paginate"
            >
                <base-pagination
                    :total="pagination.total"
                    align="center"
                    v-model="pagination.number"
                    :perPage="pagination.perPage"
                >
                </base-pagination>
            </div>
        </div>
    </div>
</template>
<script>
    import moment from 'moment';
    import swal from 'sweetalert2';// import sweet alert
    import { mapActions } from 'vuex';

    export default {
        name: 'NotificationsData',
        props: ['records', 'hasPagination', 'pagination', 'paginate', 'loadNotifications'],
        filters: {
            formatDate(value) {
                return value ? moment(value).format('Do MMM, YYYY') : 'N/A';
            },
        },
        methods: {
            ...mapActions(['deleteCpdNotification']),
            removeNotification(id) {// get full name
                swal.fire({
                    title: 'Are you sure?',
                    text: "You won't be able to revert this!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, Proceed!'
                }).then((result) => {
                    if (result.value) {
                        this.makeRemoveApiCall(id);// make api call
                    }
                });
            },
            makeRemoveApiCall(id) {
                this.preloader(); // show loading
                this.deleteCpdNotification(id).then((res) => {// delete
                    if(res.status) {
                        swal.fire("Notification Deleted", res.msg, "success");
                    } else {
                        swal.fire("Error Occurred!", res.msg, "warning");
                    }
                    this.loadNotifications();// refresh page
                }).
                catch(err => {
                    this.loadNotifications();// refresh page
                    swal.fire(err.message+"!", "An error occurred while deleting, try again", "warning");
                });
            },
            preloader() {
                return  swal.fire({
                    title: 'Please Wait...',
                    html: 'Trying to fetch data from <b>server</b>',
                    // timer: 2000,
                    timerProgressBar: true,
                    onBeforeOpen: () => {
                        swal.showLoading()
                    },
                });
            }
        }
    };
</script>
<style>
    .table td {
        font-size: 15px;
    }
</style>
