<template>
    <div>
        <base-header class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center no-padding-bottom">
            <span class="mask bg-gradient-danger opacity-8"></span>
        </base-header>

        <div class="container-fluid mt--7">
            <div class="row">
                <div class="col-xl-12 order-xl-1">
                    <br><br><br>
                    <card shadow type="secondary">
                        <div slot="header" class="bg-white border-0">
                            <div class="row align-items-center">

                                <div class="col-8">
                                    <h3 class="mb-0 text-uppercase">{{ this.$route.name }}</h3>
                                    <br>
                                    <span class="text-danger">
                                        Kindly enter a valid RRR (must have been paid for) and click on Proceed Now to
                                        submit Re-Accreditation Visitation Application...
                                    </span>
                                </div>
                                <div class="col-4 text-right">
                                    <router-link
                                        to="/visitation-payment"
                                        class="btn btn-danger"
                                    >
                                        <i class="fa fa-arrow-left"></i> Back
                                    </router-link>
                                </div>
                            </div>
                        </div>

                        <div class="row" v-if='! showErrorStatus && ! isLoadingBlade'>
                            <div class="col-lg-4"></div>
                            <div class="col-lg-4 text-center">
                                <base-input alternative=""
                                    label="RRR"
                                    placeholder="Enter Valid RRR"
                                    input-classes="form-control-alternative"
                                    v-model="rrr"
                                    required
                                />
                            </div>
                        </div>

                        <div class="col-lg-12" v-if="isLoadingBlade">
                            <div class="d-flex justify-content-center">
                                <img :src="loadingBladeUrl" />
                            </div>
                        </div>

                        <div class="col-lg-12 text-center" v-if='showErrorStatus && ! isLoadingBlade'>
                            <base-alert :type="errorType">
                                <!-- <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span> -->
                                <span class="alert-inner--text">
                                    <h2 class="text-white">{{errorMsg}}</h2>
                                </span>
                            </base-alert>
                        </div>

                        <div v-if='! showErrorStatus && ! isLoadingBlade'>
                            <hr>
                            <router-link
                                to="/visitation-payment"
                                class="btn btn-danger"
                            >
                                <i class="fa fa-arrow-left"></i>
                                Go Back
                            </router-link>

                            <base-button
                                type="success"
                                class="right"
                                :disabled="! rrr"
                                @click="getRRRStatus()"
                            >
                                {{ isloading ? 'Please Wait...' : 'Proceed Now'}}
                                <i class="fa fa-arrow-right"></i>
                            </base-button>
                            <br><br><br><br>
                        </div>

                    </card>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex';
    import swal from 'sweetalert2';// import sweet alert

    export default {
        name: 'ReVisitationRrr',
        data() {
            return {
                rrr: null,
                isloading: false,
                showErrorStatus: false,
                errorType: null,
                errorMsg: null,
                isLoadingBlade: false
            }
        },
        computed: {
            ...mapGetters(['loadingBladeUrl'])
        },
        methods: {
            ...mapActions(['reVisitationRrrStatus']),
            getRRRStatus() {
                if(this.rrr) {
                    this.isloading = true;
                    this.preloader();
                    this.reVisitationRrrStatus(this.rrr).then((res) => {
                        this.isloading = false;
                        if(res.status) {
                            swal.fire("Payment Successful!", res.msg, "success");
                            return window.location.href = '/visitation-preview';
                        } else {
                            this.isLoadingBlade = false;
                            swal.fire("Payment Failed!", res.msg, "warning");
                            setTimeout(function() { window.location.href = '/visitation-payment'; }, 5000);
                        }
                    }).
                    catch(() => {
                        this.isloading = false;
                        let msg = "Network Failure!, kindly reload the page...";
                        swal.fire("Error Ocurred!", msg, "warning");
                    });
                } else {
                    let msg = 'Kindly fill the necessary field(s) and try again...';
                    swal.fire("Empty field(s)!", msg, "warning");
                }
            },
            preloader() {
                return  swal.fire({
                    title: 'Please Wait...',
                    html: 'Trying to fetch data from <b>server</b>',
                    // timer: 2000,
                    timerProgressBar: true,
                    onBeforeOpen: () => {
                        swal.showLoading()
                    },
                });
            }
        }
    }
</script>

<style scoped>
    .no-padding-bottom {
        padding-bottom: 0rem !important
    }
    .right {
        float: right;
    }
</style>

<style>
    .form-control-label {
        width: 100%;
    }
    .height-50 {
        height: 50px;
    }
    .borderLine {
        border: 1px solid #ededed;
    }
</style>