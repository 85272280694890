<template>
    <div>
        <base-header class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center">
            <span class="mask bg-gradient-danger opacity-8"></span>
        </base-header>



        <div class="container-fluid mt--7">
            <div class="row">

                <div class="col-lg-12" v-if="isLoadingBlade">
                    <div class="d-flex justify-content-center">
                        <img :src="loadingBladeUrl" />
                    </div>
                </div>

                <div class="col-xl-12 order-xl-1" v-if="! isLoadingBlade">
                    <card shadow type="secondary">

                        <div slot="header" class="bg-white border-0">
                            <div class="row align-items-center">
                                <div class="col-8">
                                    <h4 class="mb-0 text-uppercase">
                                        Application details for
                                        <em class="text-red" v-if="record">
                                            CPD {{ record.type }}
                                        </em>
                                    </h4>
                                </div>
                                <div class="col-4 text-right">
                                    <router-link
                                        to="/applications"
                                        class="btn btn-sm btn-danger"
                                    >
                                        <i class="fa fa-reply"></i> Back
                                    </router-link>
                                </div>
                            </div>
                        </div>

                        <div class="pl-lg-4">
                            <div class="container ct-example-row">
                                <div class="row">
                                    <div class="col">
                                        <ul
                                            class="list-unstyled"
                                            v-if="record && record.transaction"
                                        >
                                            <li>
                                                <h3>
                                                    Application Status: &nbsp;
                                                    <badge :type="getStatusColor(record.status)">
                                                        {{ record.status }}
                                                    </badge>
                                                </h3>
                                                <hr class="my-4">
                                                <h3>
                                                    Transaction Id: &nbsp;
                                                    <small>
                                                        {{ record.transaction && record.transaction.trans_no
                                                                ? record.transaction.trans_no : 'N/A' }}
                                                    </small>
                                                </h3>
                                                <hr class="my-4">
                                                <h3>
                                                    Transaction Amount: &nbsp;
                                                    <small>{{ record.transaction && record.transaction.amount | formatPrice }}</small>
                                                </h3>
                                                <hr class="my-4">
                                                <h3>
                                                    Transaction Date: &nbsp;
                                                    <small v-if="record.transaction">
                                                        {{ record.transaction.date_paid | getDateFormat }}
                                                    </small>
                                                </h3>
                                                <hr class="my-4">
                                                <h3 v-if="record.transaction">
                                                    Payment RRR: &nbsp;
                                                    <small>{{ record.transaction.payment_ref ? record.transaction.payment_ref : 'N/A' }}</small>
                                                </h3>
                                                <hr
                                                    class="my-4"
                                                    v-if="((record.status == 'rejected') || (record.status == 'approved'))"
                                                >
                                                <h3
                                                    v-if="((record.status == 'rejected') || (record.status == 'approved'))"
                                                    class="text-capitalize"
                                                >
                                                    {{ record.status }} Date: &nbsp;
                                                    <small>
                                                        {{ record.approved_date | getDateFormat }}
                                                    </small>
                                                </h3>
                                                <hr class="my-4" v-if="record.status == 'rejected'">
                                                <h3 v-if="record.status == 'rejected'">
                                                    Reject Reason: &nbsp;
                                                    <small>{{ record.reject_reason ? record.reject_reason : 'N/A' }}</small>
                                                </h3>
                                                <hr class="my-4">
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div v-if="enableEdit">
                                <router-link
                                    :to="'/edit-application/' + record.id"
                                    class="btn btn-lg btn-info"
                                >
                                    <i class="fa fa-pen"></i>
                                    {{ isPending }} Application
                                </router-link>
                            </div>

                            <div class="col-sm-12 text-center">
                                <base-alert :type="errorType" v-if='showErrorStatus'>
                                    <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                                    <span class="alert-inner--text">
                                        {{ errorMsg }}
                                    </span>
                                </base-alert>
                            </div>

                        </div>

                    </card>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex';
    import moment from 'moment';
    import swal from 'sweetalert2';// import sweet alert

    export default {
        name: 'ApplicationDeatils',
        data() {
            return {
                record: {},
                errorType: null,
                showErrorStatus: false,
                errorMsg: null,
                isloading: false,
                isLoadingBlade: true
            }
        },
        filters: {
            getDateFormat(value) {
                if (value) {
                    let split = value.split(' ');
                    let date = split && split.length > 0 ? split[0] : value;
                    return date ? moment(date).format('Do MMM, YYYY') : 'N/A';
                } else {
                    return 'N/A';
                }
            },
            formatPrice(value) {
                if(value) {
                    let val = (value/1).toFixed(2).replace(',', '.')
                    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")+' NGN'
                } else {
                    return 'N/A';
                }
            }
        },
        computed: {
            ...mapGetters(['loadingBladeUrl']),
            enableEdit() {
                return this.record && (this.record.status == 'rejected');
            },
            isPending() {
                return this.record && (this.record.status == 'pending') ? 'Continue' : 'Edit';
            }
        },
        methods: {
            ...mapActions(['getApplication', 'reSubmitApplication']),
            loadAppplication() {// load insurance details
                const id = this.$route.params.id;
                this.getApplication(id).then((res) => {// get application record
                    this.isLoadingBlade = false;
                    this.record = res.application;
                }).
                catch(() => {
                    this.isLoadingBlade = false;
                    this.showErrorStatus = true;
                    this.errorMsg = "Network Failure!, kindly reload the page...";
                    this.errorType = 'danger';
                });
            },
            getStatusColor(status) {
                if(status == 'approved') {
                    return 'success';
                } else if(status == 'pending') {
                    return 'warning';
                } else {
                    return 'danger';
                }
            },
            editApplication(id) {// redirect to pending application
                return window.location.href = '/edit-application/'+id;
            },
            reSendApplication(id) {// re-send rejected application
                swal.fire({
                    title: 'Are you sure?',
                    text: "You must have corrected the issue before proceeding to re-submit application!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, I have corrected it!'
                }).then((result) => {
                    if (result.value) {
                        this.makeReSubmitApiCall(id);// make api call
                    }
                });
            },
            makeReSubmitApiCall(id) {
                this.preloader(); // show loading
                this.reSubmitApplication(id).then((res) => {// get application record
                    if(res.status) {
                        this.loadAppplication();// load application
                        swal.fire("Application Submitted!", res.msg, "success");
                    } else {
                        swal.fire("Error Occurred!", res.msg, "warning");
                    }
                }).
                catch(() => {
                    let msg = "Network Failure!, kindly reload the page...";
                    swal.fire("Network Failure!", msg, "warning");
                });
            },
            preloader() {
                return  swal.fire({
                    title: 'Please Wait...',
                    html: 'Trying to fetch data from <b>server</b> ...',
                    // timer: 2000,
                    timerProgressBar: true,
                    onBeforeOpen: () => {
                        swal.showLoading()
                    },
                });
            }
        },
        created() {
            this.loadAppplication();// load application
        },
    }
</script>