<template>
    <div>
        <base-header class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center no-padding-bottom">
            <span class="mask bg-gradient-danger opacity-8"></span>
        </base-header>

        <div class="container-fluid mt--7">
            <div class="row">
                <div class="col-xl-12 order-xl-1">
                    <br><br><br>
                    <card shadow type="secondary">
                        <div slot="header" class="bg-white border-0">
                            <div class="row align-items-center">

                                <div class="col-8">
                                    <h3 class="mb-0 text-uppercase">{{ this.$route.name }}</h3>
                                    <br>
                                    <span class="text-danger">
                                        Update your information below...
                                    </span>
                                </div>
                                <div class="col-4 text-right">
                                    <router-link
                                        to="/"
                                        class="btn btn-sm btn-primary"
                                    >
                                        <i class="ni ni-tv-2 text-white"></i>
                                        Dashboard
                                    </router-link>
                                </div>
                            </div>
                        </div>

                        <div>
                            <!-- this is hospital's profile template -->
                            <ProfileData
                                :id="application.id"
                                :data="application"
                                :transaction="application.transaction"
                                :loadCpdInfo="loadCpdInfo"
                                :displayPhoto="displayPhoto"
                                :lock="lock"
                                v-if="showProfileData && application && application.cpd && ! isLoadingBlade"
                            />

                            <!-- Import Profile Photo template here -->
                            <MDPhoto
                                :id="application.cpd.id"
                                :application="application"
                                :loadCpdInfo="loadCpdInfo"
                                :displayProfileData="displayProfileData"
                                :displayDirectorData="displayDirectorData"
                                v-if="showPhoto && application && application.cpd
                                        && application.cpd.staff && ! isLoadingBlade"
                            />

                            <!-- this is cpd's directors template -->
                            <DirectorData
                                :id="application.id"
                                :application="application"
                                :loadCpdInfo="loadCpdInfo"
                                :displayPhoto="displayPhoto"
                                :displayStaffData="displayStaffData"
                                :lock="lock"
                                v-if="showDirectorData && application && application.cpd
                                        && application.cpd.directors && ! isLoadingBlade"
                            />

                            <!-- this is hospital's Staff template -->
                            <StaffData
                                :id="application.id"
                                :application="application"
                                :loadCpdInfo="loadCpdInfo"
                                :displayDirectorData="displayDirectorData"
                                :displayConsultantData="displayConsultantData"
                                :lock="lock"
                                v-if="showStaffData && application && application.cpd
                                        && application.cpd.staff && ! isLoadingBlade"
                            />

                            <!-- this is hospital's facility template -->
                            <ConsultantData
                                :id="application.id"
                                :application="application"
                                :loadCpdInfo="loadCpdInfo"
                                :displayStaffData="displayStaffData"
                                :displaySummary="displaySummary"
                                :lock="lock"
                                :editForm="true"
                                v-if="showConsultantData && application && application.cpd
                                        && application.cpd.consultants && ! isLoadingBlade"
                            />

                            <!-- this is application summary template -->
                            <VerificationSummary
                                :application="application"
                                :displayConsultantData="displayConsultantData"
                                :resubmit="true"
                                v-if="showSummary && application && ! isLoadingBlade"
                            />

                            <div class="col-lg-12" v-if="isLoadingBlade">
                                <div class="d-flex justify-content-center">
                                    <img :src="loadingBladeUrl" />
                                </div>
                            </div>

                            <div class="col-lg-12 text-center" v-if='showErrorStatus && ! isLoadingBlade'>
                                <base-alert :type="errorType">
                                    <!-- <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span> -->
                                    <span class="alert-inner--text">
                                        <h2 class="text-white">{{errorMsg}}</h2>
                                    </span>
                                </base-alert>
                            </div>
                        </div>
                    </card>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex';
    import ProfileData from '../../Verification/Application/ProfileData.vue';
    import DirectorData from '../../Verification/Application/DirectorData';
    import StaffData from '../../Verification/Application/StaffData';
    import ConsultantData from '../../Verification/Application/ConsultantData';
    import MDPhoto from '../../Verification/Application/MDPhoto.vue';
    import VerificationSummary from '../../Verification/Application/VerificationSummary/VerificationSummary';
    import swal from 'sweetalert2';// import sweet alert

    export default {
        name: 'EditApplicationForm',
        components: {
            ProfileData,
            DirectorData,
            StaffData,
            ConsultantData,
            MDPhoto,
            VerificationSummary
        },
        data() {
            return {
                application: null,
                showErrorStatus: false,
                errorType: null,
                errorMsg: null,
                isLoadingBlade: true,
                showProfileData: true,
                showDirectorData: false,
                showConsultantData: false,
                showStaffData: false,
                showSummary: false,
                showPhoto: false
            }
        },
        computed: {
            ...mapGetters(['websiteUrl', 'loadingBladeUrl', 'cpd']),
            lock() {
                let cpd = this.cpd;
                return cpd && (cpd.profile_lock == 'yes') ? true : false;
            }
        },
        methods: {
            ...mapActions(['getCpdApplication', 'fetchCpdProfile', 'getLocations']),
            loadCpdInfo() {
                this.isLoadingBlade = true;
                this.application = null;
                this.getCpdApplication().then((res) => {
                    this.isLoadingBlade = false;
                    if(res.status) {
                        this.application = res.application;
                    } else {
                        let msg = 'Application was not found, kindly apply now'
                        swal.fire("Error Occurred!", msg, "warning");
                        setTimeout(function() { window.location.href = '/logout'; }, 3000);
                    }
                }).
                catch(() => {
                    this.showErrorStatus = true;
                    this.isLoadingBlade = false;
                    this.errorMsg = "Network Failure!, kindly reload the page...";
                    this.errorType = 'danger';
                });
            },
            displayPhoto(value) {
                this.showProfileData = ! value;
                this.showDirectorData = ! value;
                this.showStaffData = ! value;
                this.showSummary = ! value;
                this.showPhoto = value;
                this.showConsultantData = ! value;
                this.showDocuments = ! value;
            },
            displayProfileData(value) {
                this.showProfileData = value;
                this.showDirectorData = ! value;
                this.showStaffData = ! value;
                this.showSummary = ! value;
                this.showConsultantData = ! value;
                this.showPhoto = ! value;
            },
            displayDirectorData(value) {
                this.showProfileData = ! value;
                this.showStaffData = ! value;
                this.showSummary = ! value;
                this.showDirectorData = value;
                this.showConsultantData = ! value;
                this.showPhoto = ! value;
            },
            displayStaffData(value) {
                this.showProfileData = ! value;
                this.showDirectorData = ! value;
                this.showSummary = ! value;
                this.showStaffData = value;
                this.showConsultantData = ! value;
                this.showPhoto = ! value;
            },
            displayConsultantData(value) {
                this.showProfileData = ! value;
                this.showDirectorData = ! value;
                this.showStaffData = ! value;
                this.showSummary = ! value;
                this.showConsultantData = value;
                this.showPhoto = ! value;
            },
            displaySummary(value) {
                this.showProfileData = ! value;
                this.showDirectorData = ! value;
                this.showStaffData = ! value;
                this.showConsultantData = ! value;
                this.showSummary = value;
                this.showPhoto = ! value;
            }
        },
        created() {
            this.loadCpdInfo();
            this.fetchCpdProfile();
            this.getLocations();
        }
    }
</script>

<style scoped>
    .no-padding-bottom {
        padding-bottom: 0rem !important
    }
    .right {
        float: right;
    }
</style>

<style>
    .form-control-label {
        width: 100%;
    }
    .height-50 {
        height: 50px;
    }
    .borderLine {
        border: 1px solid #ededed;
    }
</style>