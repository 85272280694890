<template>
    <div>
        <div class="row">
            <div class="col-lg-12">
                <h3 class="mb-0 text-uppercase">Directors' Information</h3>
                <hr class="my-4" />
            </div>

            <div class="col-lg-12" v-if="records && records.length > 0">
                <div class="table-responsive">
                    <table class="table table-bordered">
                        <thead class="text-uppercase">
                            <td width="5%">
                                <h4>#</h4>
                            </td>
                            <td width="20%">
                                <h4>Full Name <br> (Surname First)</h4>
                            </td>
                            <td width="20%">
                                <h4>Folio Number</h4>
                            </td>
                            <td width="20%">
                                <h4>Previous Names</h4>
                            </td>
                            <td width="20%">
                                <h4>Gender</h4>
                            </td>
                            <td width="20%">
                                <h4>Designation / <br> Department</h4>
                            </td>
                        </thead>
                    </table>
                </div>
                <div
                    class="table-responsive"
                    v-for="(record, index) in records"
                    :key="record.id"
                >
                    <table class="table table-bordered">
                        <tbody>
                            <td width="5%">
                                <h4 class="text-capitalize font-weight-light">
                                    {{ index+1 }}
                                </h4>
                            </td>
                            <td width="20%">
                                <h4 class="text-capitalize font-weight-light">
                                    {{ fullName(record) }}
                                </h4>
                            </td>
                            <td width="20%">
                                <h4 class="text-capitalize font-weight-light">
                                    {{ record.folio_number ? 'MDCN/R/' + record.folio_number : 'N/A' }}
                                </h4>
                            </td>
                            <td width="20%">
                                <h4 class="text-capitalize font-weight-light">
                                    {{ record.previous_name ? record.previous_name : 'N/A' }}
                                </h4>
                            </td>
                            <td width="20%">
                                <h4 class="text-capitalize font-weight-light">
                                    {{ record.gender ? record.gender : 'N/A' }}
                                </h4>
                            </td>
                            <td width="20%">
                                <h4 class="text-capitalize font-weight-light">
                                    {{ record.designation ? record.designation : 'N/A' }}<br>
                                    <badge type="success">
                                        {{ record.department ? record.department : 'N/A' }}
                                    </badge>
                                </h4>
                            </td>
                        </tbody>
                    </table>
                </div>
            </div>

            <div class="col-lg-12 text-center" v-if="records && records.length == 0">
                <base-alert type="danger">
                    <!-- <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span> -->
                    <span class="alert-inner--text">
                        <h2 class="text-white">No Directors' Records !!!</h2>
                    </span>
                </base-alert>
            </div>

            <div class="col-lg-12">
                <hr class="my-4" />
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'NurseDetails',
        props: [ 'records' ],
        methods: {
            fullName(data) {// get full name
                let name = data.surname ? data.surname : '';
                name += data.first_name ? ' ' + data.first_name : '';
                name += data.other_name ? ' ' + data.other_name : '';
                return name;
            }
        }
    }
</script>

<style scoped>
    .no-padding-bottom {
        padding-bottom: 0rem !important
    }
    .right {
        float: right !important;
    }
    .photo-class {
        width: 200px;
        height: 200px;
    }
</style>

<style>
    .form-control-label {
        width: 100%;
    }
    .height-50 {
        height: 50px;
    }
    .borderLine {
        border: 1px solid #ededed;
    }
</style>