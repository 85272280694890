<template>
    <div class="col-xl-12 order-xl-1">
        <card shadow type="secondary">

            <div class="col-lg-12" v-if="isLoadingBlade">
                <div class="d-flex justify-content-center">
                    <img :src="loadingBladeUrl" />
                </div>
            </div>

            <RegistrationPayment
                :application="application"
                :serviceType="serviceType"
                v-on:addPayment="addPayment"
                :applicationType="applicationType"
                :totalAmount="totalAmount"
                :payNow="payNow"
                v-if="! completePage && ! showAddPayment && ! isLoadingBlade"
            />

            <AddPayment
                :orderNumber="orderNumber"
                :application="application"
                :loadApplication="loadApplication"
                :app_id="application.id"
                :serviceType="serviceType"
                :totalAmount="totalAmount"
                :removeAddPayment="removeAddPayment"
                :showCompletedPage="showCompletedPage"
                v-if="! completePage && showAddPayment && ! isLoadingBlade" 
            />

            <div class="col-lg-12" v-if="completePage">
                <div class="d-flex justify-content-center">
                    <img :src="completeImageUrl" />
                </div>
                <br>
                <h2 class="text-uppercase text-center">
                    Your application was submitted succesfully... 
                </h2>
                <h4 class="text-uppercase text-center text-danger">
                    Note: You will be contacted subsequently by MDCN
                </h4>
                <hr>
                <div class="text-center">
                    <router-link
                        to="/"
                        class="btn btn-lg btn-success"
                    >
                        <i class="fa fa-home"></i>
                        Dashboard Now
                    </router-link>
                </div>
                <br><br>
            </div>

        </card>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex'; //AddQualPayment
    import RegistrationPayment from './RegistrationPayment.vue';
    import AddPayment from './AddPayment.vue';
    import swal from 'sweetalert2';// import sweet alert

    export default {
        components: {
            RegistrationPayment,
            AddPayment
        },
        name: 'Registration',
        props: ['application', 'payNow'],
        data() {
            return {
                applicationType: null,
                isLoadingBlade: true,
                showErrorStatus: false,
                errorType: null,
                errorMsg: null,
                isloading: false,
                showAddPayment: false,
                orderNumber: null,
                serviceType: null,
                completePage: false
            }
        },
        filters: {
            formatPrice(value) {
                if(value) {
                    let val = (value/1).toFixed(2).replace(',', '.');
                    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                } else {
                    return 'N/A';
                }
            }
        },
        computed: {
            ...mapGetters(['loadingBladeUrl', 'completeImageUrl']),
            totalAmount() {// get total amount
                return this.applicationType.has_payment.amount;
            }
        },
        methods: {
            ...mapActions(['getTempRenewalPayment', 'saveApplicationTransaction', 'submitApplication']),
            submitNow(status = false) {// submit application
                this.preloader();// show loading
                this.submitApplication().then((res) => {
                    if(res.status) {
                        let msg = status ? 'Application has been submitted for approval' : 'Profile has been updated successfully';
                        swal.fire('Account Updated', msg, 'success');
                        return window.location.href='/';
                    } else {
                        let msg = 'An error occured while submitting application, try again';
                        swal.fire('Error Occurred!', msg, 'warning');
                        this.loadApplication();// load application
                    }
                }).
                catch(() => {
                    let msg = 'An error occured while submitting application, try again';
                    swal.fire('Error Occurred!', msg, 'warning');
                    this.loadApplication();// load application
                });
            },
            loadApplication() {// get application
                this.getTempRenewalPayment().then((res) => {
                    this.applicationType = res.type;
                    this.serviceType = res.service_type;
                    this.isLoadingBlade = false;
                }).
                catch(() => {
                    this.isLoadingBlade = false;
                    this.showErrorStatus = true;
                    this.errorMsg = "Network Failure!, kindly reload the page...";
                    this.errorType = 'danger';
                });
            },
            showCompletedPage() {
                this.showAddPayment = false;
                return this.completePage = true;
            },
            removeAddPayment() {// remove add payment
                return this.showAddPayment = false;
            },
            addPayment(number) {// add RRR
                this.orderNumber = number;
                this.showAddPayment = true;
            },
            preloader() {
                return  swal.fire({
                    title: 'Please Wait...',
                    html: 'Trying to fetch data from <b>server</b>',
                    // timer: 2000,
                    timerProgressBar: true,
                    onBeforeOpen: () => {
                        swal.showLoading()
                    },
                });
            }
        },
        created() {
            this.loadApplication();// load application
        },
    }
</script>

<style scoped>
.right {
    float: right;
}
</style>