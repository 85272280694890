<template>
    <div>
        <div v-if="! showGenerateRrr">
            <div class="col-lg-12 text-center">
                <hr>
                <h3 class="mb-0 text-uppercase">
                    Payment Details for
                    <em class="text-danger">{{ type.name }}</em>
                </h3>
                <hr>
            </div>

            <div class="container ct-example-row">
                <div class="row">
                    <div class="col">
                        <ul
                            class="list-unstyled"
                            v-if="type && type.has_payment"
                        >
                            <li>
                                <h3 class="text-center">
                                    Application Name: &nbsp;
                                    <em class="text-capitalize font-weight-light">
                                        {{ type.name }}
                                    </em>
                                </h3>
                                <br>
                                <h3 class="text-center">
                                    Total Amount: &nbsp;
                                    <em class="text-capitalize font-weight-light">
                                        {{ type.has_payment.amount | formatPrice }}
                                        NGN
                                    </em>
                                </h3>
                                <div v-if="type.has_payment && type.has_payment.has_breakdowns.length > 0">
                                    <hr class="my-4">
                                    <h3 class="text-center text-uppercase">
                                        Payment Details
                                    </h3>
                                    <hr class="my-4">
                                    <h3
                                        class="text-center"
                                        v-for="breakdown in type.has_payment.has_breakdowns"
                                        :key="breakdown.id"
                                    >
                                        {{ breakdown.name }}: &nbsp;
                                        <em class="text-capitalize font-weight-light">
                                            {{ breakdown.amount | formatPrice }}
                                            NGN
                                        </em>
                                        <br>
                                    </h3>
                                </div>
                                <hr class="my-4">
                                <h3 class="text-center">
                                    Total: &nbsp;
                                    <em class="text-capitalize font-weight-light">
                                        {{ type.has_payment.amount | formatPrice }}
                                        NGN
                                    </em>
                                </h3>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-12">
                <hr>
                <form :action="remitaPaymentUrl" method="POST">
                    <input
                        type="hidden"
                        v-for="item in paymentRecords"
                        :key="item.title"
                        :name="item.title"
                        :value="item.value"
                    />

                    <div class="right">
                        <base-button
                            type="dark"
                            @click="generateRRR()"
                        >
                            <i class="fa fa-history"></i>
                            {{ isloading ? 'Please Wait...' : 'Generate RRR &amp; Pay at the Bank'}}
                        </base-button>
                    </div>

                    <base-button
                        type="success"
                        class="right"
                        nativeType="submit"
                    >
                        <i class="fa fa-credit-card"></i>
                        {{ isloading ? 'Please Wait...' : 'Pay Directly Online Now'}}
                    </base-button>


                    <base-button
                        type="danger"
                        @click="$emit('goBack')"
                    >
                        <i class="fa fa-arrow-left"></i>
                        Go Back
                    </base-button>
                </form>
                <hr>
            </div>
        </div>


        <VerificationGenerateRrr
            :record="record"
            :rrr="rrr"
            :type="type"
            :orderId="orderId"
            v-if="showGenerateRrr"
        />

    </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex';
    import VerificationGenerateRrr from '../../Verification/VerificationGenerateRrr.vue';
    import swal from 'sweetalert2';// import sweet alert

    export default {
        name: 'ReAccreditationPaymentData',
        props: ['type', 'record', 'serviceType'],
        filters: {
            formatPrice(value) {
                if(value) {
                    let val = (value/1).toFixed(2).replace(',', '.');
                    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                } else {
                    return 'N/A';
                }
            }
        },
        components: {
            VerificationGenerateRrr
        },
        data() {
            return {
                isloading: false,
                orderId: null,
                showGenerateRrr: false,
                rrr: null
            }
        },
        computed: {
            ...mapGetters(['remitaMerchantId', 'remitaReApplicationCallBackUrl', 'remitaApiKey', 'remitaPaymentUrl']),
            gethash() {
                var sha512 = require('js-sha512');
                let text = this.remitaMerchantId+this.serviceType.remita_code+this.orderId
                    +this.type.has_payment.amount+this.remitaReApplicationCallBackUrl
                    +this.remitaApiKey;
                return sha512(text);
            },
            paymentRecords() {// get payment records
                return [
                    {'title' : 'merchantId', 'value' : this.remitaMerchantId},
                    {'title' : 'serviceTypeId', 'value' : this.serviceType.remita_code},
                    {'title' : 'amt', 'value' : this.type.has_payment.amount},
                    {'title' : 'responseurl', 'value' : this.remitaReApplicationCallBackUrl},
                    {'title' : 'hash', 'value' : this.gethash},
                    {'title' : 'payerName', 'value' : this.record.name},
                    {'title' : 'paymenttype', 'value' : ''},
                    {'title' : 'payerEmail', 'value' : this.record.email},
                    {'title' : 'payerPhone', 'value' : this.record.phone},
                    {'title' : 'orderId', 'value' : this.orderId},
                ]
            }
        },
        methods: {
            ...mapActions(['generateVerificationRrr']),
            setData() {
                this.rrr = null;
                this.orderId = null;
                return this.orderId = this.genRandomString(12);
            },
            genRandomString(length = 12) {
                var text = '';
                var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
                for (var i=0; i < length; i++) {
                    text += possible.charAt(Math.floor(Math.random() * possible.length));
                }
                return text;
            },
            generateRRR() {// generate rrr
                let data = {
                    serviceTypeCode : this.serviceType.remita_code,
                    amount : this.type.has_payment.amount,
                    orderId : this.orderId,
                    payerName : this.record.name,
                    payerEmail : this.record.email,
                    payerPhone : this.record.phone
                };
                this.isloading = true;
                this.rrr = null
                this.preloader();
                this.generateVerificationRrr(data).then((res) => {
                    this.isloading = false;
                    swal.close();
                    if(res.statuscode == "025") {
                        this.rrr = res;
                        this.showGenerateRrr = true;
                    } else {
                        this.setData();
                        let msg = "RRR was not generated, try again...";
                        swal.fire("RRR Failed!", msg, "warning");
                    }
                }).
                catch(() => {
                    this.setData();
                    this.isloading = false;
                    let msg = "Network Failure!, kindly reload the page...";
                    swal.fire("Error Ocurred!", msg, "warning");
                });
            },
            preloader() {
                return  swal.fire({
                    title: 'Please Wait...',
                    html: 'Trying to fetch data from <b>server</b>',
                    // timer: 2000,
                    timerProgressBar: true,
                    onBeforeOpen: () => {
                        swal.showLoading()
                    },
                });
            }
        },
        created() {
            this.setData();
        }
    }
</script>

<style scoped>
    .no-padding-bottom {
        padding-bottom: 0rem !important
    }
    .right {
        float: right;
    }
</style>

<style>
    .form-control-label {
        width: 100%;
    }
    .height-50 {
        height: 50px;
    }
    .borderLine {
        border: 1px solid #ededed;
    }
</style>