<template>
    <div class="row" v-if="! showAddRecord && consultantRecords && consultantRecords.length > 0">
        <div class="col-lg-12">
            <div class="card shadow">
                <div class="table-responsive">
                    <base-table
                        class="table align-items-center table-flush"
                        thead-classes="thead-light"
                        tbody-classes="list"
                        :data="consultantRecords"
                    >
                        <template slot="columns">
                            <th width="2%">S/N</th>
                            <th>Full Name <br> (Surname First)</th>
                            <th v-if="! isStaff">Folio Number</th>
                            <th>Previous Names</th>
                            <th>Gender</th>
                            <th>Designation <br> / Department</th>
                            <th width="5%">&nbsp;</th>
                        </template>

                        <template
                            slot-scope="{row, index}"
                        >
                        <td class="budget">
                            {{ pagination.from+index+1}}
                        </td>
                        <td class="text-capitalize">
                            {{ fullName(row) }}
                        </td>
                        <td class="text-capitalize" v-if="! isStaff">
                            {{ row.folio_number ? 'MDCN/R/' + row.folio_number : 'N/A' }}
                        </td>
                        <td class="text-capitalize">
                            {{ row.previous_name ? row.previous_name : 'N/A' }}
                        </td>
                        <td class="text-capitalize">
                            {{ row.gender }}
                        </td>
                        <td>
                            {{ row.designation ? row.designation : 'N/A' }} <br>
                            <badge type="success">
                                {{ row.department ? row.department : 'N/A' }}
                            </badge>
                        </td>
                        <td>
                            <base-button
                                type="info" size="sm"
                                @click="$emit('editRecord', row)"
                                :disabled="lock"
                            >
                                <i class="fa fa-pen"></i> Edit
                            </base-button>
                            <base-button
                                type="danger" size="sm"
                                @click="$emit('deleteRecord', row.id)"
                                :disabled="lock"
                            >
                                <i class="fa fa-trash"></i> Remove
                            </base-button>
                        </td>
                        </template>

                    </base-table>
                </div>

                <div
                    class="card-footer d-flex justify-content-end bg-transparent"
                    v-if="hasPagination"
                     @click="paginate"
                >
                    <base-pagination
                        :total="pagination.total"
                        align="center"
                        v-model="pagination.number"
                        :perPage="pagination.perPage"

                    >
                    </base-pagination>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'DirectorRecords',
        props: ["consultantRecords", "showAddRecord", "pagination", "hasPagination", "paginate", "lock", "isStaff"],
        methods: {
            fullName(data) {// get full name
                let name = data.surname ? data.surname : '';
                name += data.first_name ? ' ' + data.first_name : '';
                name += data.other_name ? ' ' + data.other_name : '';
                return name;
            }
        }
    }
</script>