<template>
    <div>
        <base-header class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center">
            <span class="mask bg-gradient-danger opacity-8"></span>
        </base-header>


        <div class="container-fluid mt--7">
            <div class="row">

                <div class="col-xl-12 order-xl-1">
                    <card shadow type="secondary">
                        <div slot="header" class="bg-white border-0">
                            <div class="row align-items-center">
                                <div class="col-8">
                                    <h3 class="mb-0">My Applications</h3>
                                </div>
                                <!-- <div class="col-4 text-right">
                                    <a href="#!" class="btn btn-sm btn-primary">Settings</a>
                                </div> -->
                            </div>
                        </div>

                        <template>
                            <tabs fill class="flex-column flex-md-row">
                                <card shadow>
                                    <tab-pane title="CPD Re-accreditation">
                                        <span slot="title">
                                            <i class="ni ni-archive-2"></i>
                                            CPD Re-accreditation
                                        </span>
                                        <!-- CPD Applications template here -->
                                        <CpdApplications 
                                            :type="'re-accreditation'"
                                        />
                                    </tab-pane>

                                    <tab-pane title="Visitation Applications">
                                        <span slot="title">
                                            <i class="ni ni-collection"></i>
                                            Visitation Applications
                                        </span>
                                        <!-- Visitation Applications template here -->
                                        <CpdApplications 
                                            :type="'visitation'"
                                        />
                                    </tab-pane>

                                    <tab-pane title="CPD Applications">
                                        <span slot="title">
                                            <i class="ni ni-books"></i>
                                            CPD Applications
                                        </span>
                                        <!-- CPD Applications template here -->
                                        <CpdApplications 
                                            :type="'application'"
                                        />
                                    </tab-pane>
                                </card>
                            </tabs>

                        </template>
                    </card>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import { mapActions } from 'vuex';
    import CpdApplications from './CpdApplications.vue';

    export default {
        components: {
            CpdApplications
        },
        name: 'Applications',
        methods: {
            ...mapActions(['fetchCpdProfile']),
        },
        created() {
            this.fetchCpdProfile();
        }
    };
</script>
<style scoped>
    .width600 {
        width: 600px !important;
    }
    .marginLeft23 {
        margin-left: 23px;
    }
    .image-size {
        height: 180px;
        width: 190px;
    }
</style>
