<template>
        <div class="row justify-content-center">
            <div class="col-lg-5 col-md-7">
                <div class="card bg-secondary shadow border-0">
                    <div class="card-body px-lg-5 py-lg-5">
                        <form role="form">
                             <base-alert :type="errorType" dismissible v-if="showErrorBlade">
                                <span class="alert-inner--icon"><i class="ni ni-sound-wave"></i></span>
                                &nbsp;
                                <span class="alert-inner--text" v-text="errorMessage"></span>
                                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </base-alert>
                            <base-input class="input-group-alternative mb-3"
                                        placeholder="Enter Valid Email Address"
                                        addon-left-icon="ni ni-email-83"
                                        :valid="validEmailStatus"
                                        @change="changeStatus('email')"
                                        v-model="model.email">
                            </base-input>

                            <div class="text-center">
                                <base-button type="success" class="my-4" @click="resetPassword"
                                    v-if="! isLoading">
                                    Reset Password
                                </base-button>
                                <button class="btn btn-success" type="button" disabled v-if="isLoading">
                                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                    Please Wait...
                                </button>
                            </div>

                        </form>
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-6">
                        <router-link to="/login" class="text-white">
                            <span class="font-weight-bolder">
                                Login Here
                            </span>
                        </router-link>
                    </div>
                    <div class="col-6 text-right">
                        <!-- <router-link to="/register" class="text-light"><small>Create new account</small></router-link> -->
                    </div>
                </div>
            </div>
        </div>
</template>
<script>
    import { mapGetters } from 'vuex';
    import axios from 'axios';
    import swal from 'sweetalert2';// import swee

    export default {
        name: 'ResetPassword',
        data() {
            return {
                model: {
                email: '',
                },
                showErrorBlade: false,
                errorMessage: '',
                errorType: null,
                validEmailStatus: null,
                isLoading: false
            }
        },
        computed: {
        ...mapGetters(['getResetPasswordUrl','getAuthorizationToken'])
        },
        methods: {
            resetPassword() {
                this.showErrorBlade = false;
                this.isLoading = true;
                const header = {Authorization: `Bearer ${this.getAuthorizationToken}`};
                let data = this.validateForm();//get validated data
                if(data.status) {//reset password
                    this.preloader();// show loader
                    axios.post(this.getResetPasswordUrl, this.model, { headers: header })
                    .then((res) => {
                        this.isLoading = false;
                        if(res.data.status) {// success
                            swal.fire("Password Reset Successful!", res.data.msg, "success");
                            return setTimeout(function() { window.location.href = '/'; }, 3000);
                        } else {
                            swal.fire("Error Occurred!", res.data.msg, "warning");
                        }
                    })
                    .catch(() => {//check for failure
                        this.isLoading = false;
                        let msg = 'Network Failure! Kindly try again';
                        swal.fire("Network Failure!", msg, "warning");
                    })
                } else {// show red alert
                    this.isLoading = false;
                    return this.showError(data, 'warning');
                }
            },
            validateForm() {
                let email = this.model.email;
                let data = [];
                if(email && this.validEmail(email)) {//check for valid login crendentials
                    data.status = true;
                } else {
                    data.status = false;
                    if (! email) {// check for empty email field
                        data.msg = 'Email field is empty';
                        data.type = 'email';
                    } else if(email && ! this.validEmail(email)) {//check for invalid email
                        data.msg = 'Invalid email has been inputed';
                        data.type = 'email';
                    }
                }
                return data;
            },
            validEmail(email) {
                // eslint-disable-next-line
                var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return re.test(email);
            },
            showError(data, type) {
                this.showErrorBlade = false;
                this.errorMessage = '';
                this.validEmailStatus = false;
                this.showErrorBlade = true;
                this.errorType = type;
                this.errorMessage = data.msg;
            },
            changeStatus(type) {
                this.showErrorBlade = false;
                let email = this.model.email;
                if(type == 'email') {
                    this.validEmailStatus = null;
                    this.validEmailStatus = this.validEmail(email) ? true :false;
                } else {
                    this.validEmailStatus = false;
                }
            },
            preloader() {
                return  swal.fire({
                    title: 'Resetting Password...',
                    html: 'Trying to fetch data from <b>server</b> ...',
                    // timer: 2000,
                    timerProgressBar: true,
                    onBeforeOpen: () => {
                        swal.showLoading()
                    },
                });
            }
        }
    }
</script>

<style scoped>
    .height-50 {
        height: 50px;
    }
    .borderLine {
        /* border: 2px solid #ededed; */
        background-color: #f7fafc;
    }
    .margin20 {
        margin-bottom: 20px;
    }
</style>

<style>
    .bg-default {
        background-color: #f5365c !important;
    }
    .bg-gradient-danger {
        background: linear-gradient(100deg, #f5365c 0, #96e7c4 250%) !important
    }
    .fill-default {
        fill: #f5365c;
    }
    .navbar-horizontal .navbar-brand img {
        height: 110px;
    }
    .navbar-dark .navbar-nav .nav-link {
        color: rgb(50, 50, 93);
    }
</style>
