<template>
    <div>
        <!-- <hr class="my-4">  -->
        <h2 class="mb-0 text-uppercase text-center">
            RRR Number Generated Details
        </h2>

        <hr class="my-4">

        <h4 class="mb-0 text-danger text-center">
            Note: Write down the RRR number below and keep it safe, proceed to designated bank to
            make payment. Kindly keep the RRR number safe untill you add the payment to the portal.
        </h4>

        <hr class="my-4">

        <div class="table-responsive">
            <table class="table table-bordered">
                <tbody>
                    <td>
                        <h4>RRR Number</h4>
                    </td>
                    <td>
                        <h4 class="text-capitalize font-weight-light">
                            {{ rrr.RRR }}
                        </h4>
                    </td>
                    <td>
                        <h4>Payee's Name</h4>
                    </td>
                    <td>
                        <h4 class="text-capitalize font-weight-light">
                            {{ record.name }}
                        </h4>
                    </td>
                </tbody>
                <tbody>
                    <td>
                        <h4>Application Type</h4>
                    </td>
                    <td>
                        <h4 class="text-capitalize font-weight-light">
                            {{ type.name }}
                        </h4>
                    </td>
                    <td>
                        <h4>Amount</h4>
                    </td>
                    <td>
                        <h4 class="text-capitalize font-weight-light">
                            {{ type.has_payment.amount | formatPrice }}
                        </h4>
                    </td>
                </tbody>
                <tbody>
                    <td>
                        <h4>Trans Number</h4>
                    </td>
                    <td>
                        <h4 class="text-capitalize font-weight-light">
                            {{ orderId }}
                        </h4>
                    </td>
                    <td>
                        <h4>Date Generated</h4>
                    </td>
                    <td>
                        <h4 class="font-weight-light">
                            {{ formatDateNow }}
                        </h4>
                    </td>
                </tbody>
            </table>
        </div>

        <hr class="my-4">

        <div class="text-center">
            <form :action="getRootUrl+'/print-cpd-invoice'" method="POST" target="_blank">
                <input type="hidden" name="fullname" :value="record.name" />
                <input type="hidden" name="email" :value="record.email" />
                <input type="hidden" name="phone" :value="record.phone" />
                <input type="hidden" name="app_id" :value="type.id" />
                <input type="hidden" name="rrr" :value="rrr.RRR" />
                <input type="hidden" name="date" :value="dateNow" />

                <base-button
                    type="primary" size="lg"
                    nativeType="submit"
                >
                    <i class="fa fa-print"></i>
                    Print Invoice
                </base-button>

                <router-link
                    :to="goBackUrl"
                    class="btn btn-danger"
                >
                    <i class="fa fa-arrow-left"></i>
                    Go Back
                </router-link>
            </form>
        </div>
        <hr class="my-4"> 

    </div>

</template>

<script>
    import moment from 'moment';
    import { mapActions, mapGetters } from 'vuex';

    export default {
        name: 'VerificationGenerateRrr',
        props: ["record", "type", "rrr", "orderId", "amount"],
        filters: { 
            formatPrice(value) {
                if(value) {
                    let val = (value/1).toFixed(2).replace(',', '.');
                    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")+ ' NGN';
                } else {
                    return 'N/A';
                }
            }
        },
        computed: {
            ...mapGetters(['cpd', 'getRootUrl']),
            formatDateNow() {
                return moment().format('Do MMM, YYYY [at] h:mm:ss a');
            },
            dateNow() {
                return moment().format('DD/MM/YYYY');
            },
            goBackUrl() {
                return this.cpd && (this.cpd.type == 'visitation') ? '/' : '/application-payment';
            }
        },
        methods: {
            ...mapActions(['fetchCpdProfile'])
        },
        created() {
            this.fetchCpdProfile();
        }
    }
</script>

<style scoped>
.right {
    float: right;
}
</style>