<template>
    <div>
        <base-header class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center">
            <span class="mask bg-gradient-danger opacity-8"></span>
        </base-header>


        <div class="container-fluid mt--7">
            <div class="row">

                <div class="col-xl-12 order-xl-1">
                    <card shadow type="secondary">
                        <div slot="header" class="bg-white border-0">
                            <div class="row align-items-center">
                                <div class="col-8">
                                    <h3 class="mb-0 text-uppercase">{{ this.$route.name }}</h3>
                                </div>
                                <div class="col-4 text-right">
                                    <router-link
                                        to="/notifications"
                                        class="btn btn-sm btn-danger"
                                    >
                                        <i class="fa fa-reply"></i>
                                        Back
                                    </router-link>
                                </div>
                            </div>
                        </div>

                        <div class="row" v-if="notification && ! isLoadingBlade">
                            <div class="col-lg-12">
                                <hr>
                                <h3>
                                    Subject:
                                    <span class="font-weight-light">
                                        {{ notification.subject }}
                                    </span>
                                </h3>
                                <hr>
                            </div>
                            <div class="col-lg-12">
                                <h3>
                                    Content:
                                </h3>
                                <hr>
                            </div>
                            <div class="col-lg-12">
                                <span
                                    class="font-weight-light"
                                    v-html="notification.message"
                                ></span>
                                <hr>
                            </div>
                        </div>

                        <div class="row" v-if="! notification && ! isLoadingBlade">
                            <div class="col-lg-12 text-center">
                                <base-alert type="warning">
                                    <span class="alert-inner--text">
                                        <h3 class="text-white">
                                            Record couldn't be feteched, try again...
                                        </h3>
                                    </span>
                                </base-alert>
                            </div>
                        </div>


                        <div class="text-center" v-if='showErrorStatus'>
                            <base-alert :type="errorType">
                                <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                                <span class="alert-inner--text">
                                    {{errorMsg}}
                                </span>
                            </base-alert>
                        </div>

                        <div class="col-lg-12" v-if="isLoadingBlade">
                            <div class="d-flex justify-content-center">
                                <img :src="loadingBladeUrl" />
                            </div>
                        </div>

                    </card>
                </div>

            </div>
        </div>



    </div>
</template>
<script>
    // get user's details from store
    import { mapActions, mapGetters } from 'vuex';

    export default {
        name: 'SingleNotification',
        data() {
            return {
                slug: this.$route.params.slug,
                notification: null,
                showErrorStatus: false,
                errorType: null,
                errorMsg: null,
                isLoadingBlade: true
            }
        },
        computed: {
            ...mapGetters(['loadingBladeUrl'])
        },
        methods: {
            ...mapActions(['getCpdNotification']),
            loadNotification() {
                this.getCpdNotification(this.slug).then((res) => {
                    this.notification = res.notification;
                    this.isLoadingBlade = false;
                }).
                catch(() => {
                    this.showErrorStatus = true;
                    this.isLoadingBlade = false;
                    this.errorMsg = "Network Failure!, kindly reload the page...";
                    this.errorType = 'danger';
                });
            },
        },
        created() {
            this.loadNotification();
        }
    };
</script>
<style scoped>
    .width600 {
        width: 600px !important;
    }
    .marginLeft23 {
        margin-left: 23px;
    }
    .image-size {
        height: 180px;
        width: 190px;
    }
</style>
