<template>
    <div>
        <base-header class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center no-padding-bottom">
            <span class="mask bg-gradient-danger opacity-8"></span>
        </base-header>

        <div class="container-fluid mt--7">
            <div class="row">
                <div class="col-xl-12 order-xl-1">
                    <br><br><br>
                    <card shadow type="secondary">
                        <div slot="header" class="bg-white border-0">
                            <div class="row align-items-center">

                                <div class="col-8">
                                    <h3 class="mb-0 text-uppercase">{{ this.$route.name }}</h3>
                                    <br>
                                    <span class="text-danger">
                                        Kindly fill the form below and proceed to make payment for Re-Accreditation
                                        Visitation fee by either generating RRR and pay through designated Banks or 
                                        Pay online now
                                    </span>
                                </div>
                                <div class="col-4 text-right">
                                    <router-link
                                        to="/visitation-payment"
                                        class="btn btn-danger"
                                    >
                                        <i class="fa fa-arrow-left"></i>
                                        Back
                                    </router-link>
                                </div>
                            </div>
                        </div>

                        <div v-if="! showPaymentPage">
                            <div class="row" v-if='! isLoadingBlade'>
                                <div class="col-lg-6">
                                    <base-input alternative=""
                                        label="Payee's Full Name"
                                        placeholder="Payee's Full Name"
                                        input-classes="form-control-alternative"
                                        v-model="record.name"
                                        required
                                    />
                                </div>

                                <div class="col-lg-6">
                                    <base-input alternative=""
                                        label="Payee's Email Address"
                                        placeholder="Payee's Email Address"
                                        input-classes="form-control-alternative"
                                        v-model="record.email"
                                        required
                                    />
                                </div>

                                <div class="col-lg-6">
                                    <base-input alternative=""
                                        label="Payee's Phone Number"
                                        placeholder="Payee's Phone Number"
                                        input-classes="form-control-alternative"
                                    >
                                    <!-- v-model="profile.phone"  -->
                                        <VuePhoneNumberInput
                                            v-model="tel.phone"
                                            @update="updatePhoneNumber"
                                            :default-country-code="tel.code"
                                            :no-example="true"
                                            :clearable="true"
                                        />
                                    </base-input>
                                </div>

                            </div>

                            <div class="col-lg-12" v-if="isLoadingBlade">
                                <div class="d-flex justify-content-center">
                                    <img :src="loadingBladeUrl" />
                                </div>
                            </div>

                            <div class="col-lg-12 text-center" v-if='showErrorStatus && ! isLoadingBlade'>
                                <base-alert :type="errorType">
                                    <!-- <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span> -->
                                    <span class="alert-inner--text">
                                        <h2 class="text-white">{{errorMsg}}</h2>
                                    </span>
                                </base-alert>
                            </div>
                        </div>

                        <ReVisitationGenerateData
                            :type="type"
                            :record="record"
                            :serviceType="serviceType"
                            v-on:goBack="goBack"
                            v-if="showPaymentPage"
                        />

                        <div v-if="! showErrorStatus && ! showPaymentPage">
                            <hr>
                            <router-link
                                to="/visitation-payment"
                                class="btn btn-danger"
                                :disabled="isLoadingBlade"
                            >
                                <i class="fa fa-arrow-left"></i>
                                Go Back
                            </router-link>

                            <base-button
                                type="success"
                                class="right"
                                :disabled="isLoadingBlade"
                                @click="getPayments()"
                            >
                                {{ isLoadingBlade ? 'Please Wait...' : 'Proceed Now'}}
                                <i class="fa fa-arrow-right"></i>
                            </base-button>
                            <br><br><br><br>
                        </div>

                    </card>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex';
    import ReVisitationGenerateData from './ReVisitationGenerateData.vue';
    import VuePhoneNumberInput from 'vue-phone-number-input';
    import 'vue-phone-number-input/dist/vue-phone-number-input.css';
    import swal from 'sweetalert2';// import sweet alert

    export default {
        name: 'ReVisitationGenerate',
        components: {
            ReVisitationGenerateData,
            VuePhoneNumberInput
        },
        data() {
            return {
                isLoadingBlade: false,
                record: {
                    name: null,
                    email: null,
                    phone: null
                },
                tel: {
                    code: 'NG',
                    phone: null
                },
                showErrorStatus: false,
                errorType: null,
                errorMsg: null,
                type: null,
                serviceType: null,
                showPaymentPage: false
            }
        },
        computed: {
            ...mapGetters(['loadingBladeUrl', 'cpd'])
        },
        methods: {
            ...mapActions(['getReVisitationPayment', 'fetchCpdProfile']),
            loadProfile() {
                this.emptyFields();
                let data = this.cpd;
                if(data) {
                    this.record = {
                        name: data.name,
                        email: data.email,
                        phone: null
                    };
                    this.loadPhoneNumber(data.phone_code); // let phone number
                }
            },
            loadPhoneNumber(data) {// update phone no
                let exploded = data ? data.split('-') : false;
                this.tel.code = exploded && exploded[0] ? exploded[0] : 'NG';
                this.tel.phone = exploded && exploded[1] ? exploded[1] : null;
            },
            updatePhoneNumber(event) {
                if(event && event.isValid && event.e164) {
                    this.record.phone = event.formattedNumber;
                }
            },
            goBack() {
                this.loadProfile();
                this.type = null;
                this.serviceType = null;
                this.showPaymentPage = false;
            },
            getPayments() {
                this.isloading = true;
                this.preloader();// show loading
                let status = this.validatedData();// valid input data
                if(status) {// update
                    this.getReVisitationPayment().then((res) => {
                        this.isloading = false;
                        if(res.status) {
                            this.type = res.type;
                            this.serviceType = res.service_type;
                            this.showPaymentPage = true;
                            swal.close()
                        } else {
                            let msg = "Payment details could not be found, reload page";
                            swal.fire("Error Ocurred!", msg, "warning");
                        }
                    }).
                    catch(() => {
                        this.isloading = false;
                        let msg = "Network Failure!, kindly reload the page...";
                        swal.fire("Error Ocurred!", msg, "warning");
                    });
                }   else {
                    this.isloading = false;
                    swal.close();
                }
            },
            emptyFields() {
                this.record = {
                    name: null,
                    email: null,
                    phone: null
                };
                this.tel = { code: 'NG', phone: null };
            },
            validatedData() {
                let data = this.record;
                data.phone != null ? data.phone : this.showInuptError('Payees\'s Phone Number');
                data.email != null ? data.email : this.showInuptError('Payees\'s Email Address');
                data.name != null ? data.name : this.showInuptError('Payees\'s Full Name');
                let status = data.name && data.email && data.phone;
                return status ? true : false;
            },
            showInuptError(name) {
                this.isloading = false;
                this.showErrorStatus = true
                this.errorType = 'warning'
                this.errorMsg = 'Invalid ' + name + ' has been entered';
                var self = this;
                setTimeout(function() { self.showErrorStatus = false; }, 5000);
            },
            preloader() {
                return  swal.fire({
                    title: 'Please Wait...',
                    html: 'Trying to fetch data from <b>server</b>',
                    // timer: 2000,
                    timerProgressBar: true,
                    onBeforeOpen: () => {
                        swal.showLoading()
                    },
                });
            }
        },
        created() {
            this.fetchCpdProfile();
            this.loadProfile();
        }
    }
</script>

<style scoped>
    .no-padding-bottom {
        padding-bottom: 0rem !important
    }
    .right {
        float: right;
    }
</style>

<style>
    .form-control-label {
        width: 100%;
    }
    .height-50 {
        height: 50px;
    }
    .borderLine {
        border: 1px solid #ededed;
    }
</style>