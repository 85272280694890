<template>
    <div>
 
        <div>
            <hr class="my-4">
            <h2
                class="mb-0 text-uppercase text-center"
                v-if="applicationType && applicationType.has_payment"
            >
                Make New Payment for
                <em class="text-danger">
                    {{ applicationType.name }}
                </em>
            </h2>
        </div>

        <div class="container ct-example-row">
            <div class="row" v-if="applicationType && applicationType.has_payment">
                <div class="col-lg-12">
                    <ul class="list-unstyled">
                        <li>
                            <hr class="my-4">
                            <h3 class="text-center">
                                Application Name: &nbsp;
                                <em class="text-capitalize font-weight-light">
                                    {{ applicationType.name }}
                                </em>
                            </h3>
                            <br>
                            <h3 class="text-center">
                                Total Amount: &nbsp;
                                <em class="text-capitalize font-weight-light">
                                    {{ totalAmount | formatPrice }}
                                </em>
                            </h3>

                            <div v-if="applicationType && applicationType.has_payment && applicationType.has_payment.has_breakdowns
                                        && applicationType.has_payment.has_breakdowns.length > 0"
                            >
                                <hr class="my-4">
                                <h1 class="text-center">
                                    Payment Breakdowns
                                </h1>
                                <hr class="my-4">
                                <h3
                                    v-for="breakdown in applicationType.has_payment.has_breakdowns"
                                    :key="breakdown.id"
                                >
                                    {{ breakdown.name }}: &nbsp;
                                    <em class="text-capitalize font-weight-light right">
                                        {{ breakdown.amount | formatPrice }}
                                        </em>
                                    <br>
                                </h3>

                                <hr class="my-4">
                                <h3>
                                    Total: &nbsp;
                                    <em class="text-capitalize font-weight-light right">
                                        {{ totalAmount | formatPrice }}
                                    </em>
                                </h3>
                            </div>
                        </li>
                    </ul>
                </div>

                <div class="col-lg-12 text-center" v-if='showErrorStatus'>
                    <base-alert :type="errorType">
                        <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                        <span class="alert-inner--text">
                            {{ errorMsg }}
                        </span>
                    </base-alert>
                </div>

                <div class="col-lg-12">
                    
                    <h3 class="text-justify text-danger">
                        <hr class="my-4">
                        Note: <br>
                        1. You can pay online or generate Remita RRR Number and pay through designated banks. <br>
                        2. If You want to pay online with Debit Cards, click on 
                        <span class="text-success">Pay Online Now</span> button below, <br>
                        3. Else if You want to generate RRR or You have a valid RRR (i.e. You have made payment 
                        already through any of the designated banks) and want to add payment now, click on  
                        <span class="text-primary">Pay Via RRR</span> below 
                    </h3>

                    <hr class="my-4">

                    <form
                        :action="remitaPaymentUrl" method="POST"
                    >
                        <input
                            type="hidden"
                            v-for="(item, index) in paymentRecords"
                            :key="index"
                            :name="item.title"
                            :value="item.value"
                        />

                        <base-button
                            type="danger"
                            @click="payNow(false)"
                        >
                            <i class="fa fa-arrow-left"></i>
                            Previous
                        </base-button>

                        <base-button
                            type="success"
                            nativeType="submit"
                        >
                            <i class="fa fa-credit-card"></i>
                            Pay Online Now
                        </base-button>

                        <div class="right">
                            <base-button
                                type="primary"
                                @click="$emit('addPayment', orderId)"
                            >
                                <i class="fa fa-plus"></i>
                                Pay Via RRR
                            </base-button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import moment from 'moment';
    import swal from 'sweetalert2';// import sweet alert

    export default {
        name: 'RegistrationPayment',
        props: ['application', 'serviceType', 'payNow', 'applicationType', 'totalAmount'],
        data() {
            return {
                isLoadingBlade: false,
                showErrorStatus: false,
                errorType: null,
                errorMsg: null
            }
        },
        filters: {
            formatPrice(value) {
                if(value) {
                    let val = (value/1).toFixed(2).replace(',', '.');
                    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")+ ' NGN';
                } else {
                    return 'N/A';
                }
            }
        },
        computed: {
            ...mapGetters(['loadingBladeUrl', 'remitaPaymentUrl', 'remitaMerchantId', 'remitaRenewalCallbackUrl', 
                'remitaApiKey', 'cpd']),
            orderId() {// get order id
                return this.genRandomString();
            },
            gethash() {
                var sha512 = require('js-sha512');
                let text = this.remitaMerchantId+this.serviceType.remita_code+this.orderId
                    +this.totalAmount+this.remitaRenewalCallbackUrl+this.remitaApiKey;
                return sha512(text);
            },
            paymentRecords() {// get payment records
                return [
                    {'title' : 'merchantId', 'value' : this.remitaMerchantId},
                    {'title' : 'serviceTypeId', 'value' : this.serviceType.remita_code},
                    {'title' : 'amt', 'value' : this.totalAmount},
                    {'title' : 'responseurl', 'value' : this.remitaRenewalCallbackUrl},
                    {'title' : 'hash', 'value' : this.gethash},
                    {'title' : 'payerName', 'value' : this.cpd.name},
                    {'title' : 'paymenttype', 'value' : ''},
                    {'title' : 'payerEmail', 'value' : this.cpd.email},
                    {'title' : 'payerPhone', 'value' : this.cpd.phone},
                    {'title' : 'orderId', 'value' : this.orderId},
                ]
            }
        },
        methods: {
            ...mapActions(['fetchCpdProfile']),
            genRandomString() {
                var length = 12;
                var text = '';
                var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
                for (var i=0; i < length; i++) {
                    text += possible.charAt(Math.floor(Math.random() * possible.length));
                }
                return text;
            }
        },
        created() {
            this.fetchCpdProfile();
        },
    }
</script>

<style scoped>
.right {
    float: right;
}
</style>