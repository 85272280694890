<template>
    <div>
        <div v-if="! isLoadingBlade && ! showErrorStatus">
            <h3 class="text-uppercase">Cpd Provider Profile</h3>
            <hr>
            <div class="row">
                <div class="col-lg-4">
                    <base-input alternative=""
                        label="Institution / Organization's Name"
                        placeholder="Enter Institution's Name"
                        input-classes="form-control-alternative"
                        v-model="record.name"
                        required
                    />
                </div>

                 <div class="col-lg-4">
                    <base-input alternative=""
                        label="CPD Provider's Email Address"
                        placeholder="Enter Email Address"
                        input-classes="form-control-alternative"
                        v-model="record.email"
                        required
                        readonly
                    />
                </div>

                <div class="col-lg-4">
                    <base-input alternative=""
                        label="CPD Provider's Phone Number"
                        placeholder="Phone Number"
                        input-classes="form-control-alternative"
                        required
                    >
                    <!-- v-model="profile.phone"  -->
                        <VuePhoneNumberInput
                            v-model="tel.phone"
                            @update="updatePhoneNumber"
                            :default-country-code="tel.code"
                            :no-example="true"
                            :clearable="true"
                        />
                    </base-input>
                </div>

                <div class="col-lg-12">
                    <base-input alternative=""
                        label="Office Address (Note without State because State will be selected below)"
                        required
                    >
                        <textarea
                            rows="2"
                            class="form-control form-control-alternative"
                            placeholder="Enter Office Address"
                            v-model="record.location"
                        ></textarea>
                    </base-input>
                </div>

                <div class="col-lg-6">
                    <base-input alternative=""
                        label="State Location"
                        required
                    >
                        <autocomplete
                            v-if="locations.states && locations.states.length > 0"
                            input-class="form-control form-control-alternative"
                            placeholder="Type to Search for State"
                            showNoResults
                            :source="locations.states"
                            v-model="record.state_id"
                            :initialDisplay="(state ? state.name : '')"
                        >
                        </autocomplete>
                    </base-input>
                </div>

                <div class="col-lg-6">
                    <base-input alternative=""
                        label="CPD Provider's Website"
                        placeholder="Enter Website"
                        input-classes="form-control-alternative"
                        v-model="record.website"
                    />
                </div>

                <div class="col-lg-6">
                    <base-input alternative=""
                        label="Category of Organization"
                        required
                    >
                        <select
                            class="form-control form-control-alternative"
                            v-model="record.category"
                        >
                            <option :value="null" disabled selected>--- Select One ---</option>
                            <option value="government">Government</option>
                            <option value="ngo">NGO</option>
                            <option value="private">Private</option>
                        </select>
                    </base-input>
                </div>

                <div class="col-lg-6">
                    <base-input alternative=""
                        label="Registration with CAC (Where Applicable)"
                    >
                        <select
                            class="form-control form-control-alternative"
                            v-model="record.cac"
                        >
                            <option :value="null" disabled selected>--- Select One ---</option>
                            <option value="limited-liability">Limited Liability</option>
                            <option value="plc">Plc</option>
                            <option value="enterprise">Enterprise/Business Name</option>
                        </select>
                    </base-input>
                </div>

                <div class="col-lg-12">
                    <base-input alternative=""
                        label="Type of Accreditation Requested"
                        v-model="record.accreditation"
                        required
                    >
                        <base-checkbox
                            class="mb-3"
                            v-model="item.status"
                            v-for="(item , index) in accreditation"
                            :key="index"
                        >
                            {{ item.name }}
                        </base-checkbox>
                    </base-input>
                </div>

                <div class="col-lg-12">
                    <base-input alternative=""
                        label="Experience/Evidence of previous Performance (if any)"
                    >
                        <textarea
                            rows="2"
                            class="form-control form-control-alternative"
                            placeholder="Enter Experience/Evidence of previous Performance"
                            v-model="record.experience"
                        ></textarea>
                    </base-input>
                </div>

            </div>
        </div>

        <div class="col-lg-12" v-if="isLoadingBlade">
            <div class="d-flex justify-content-center">
                <img :src="loadingBladeUrl" />
            </div>
        </div>

        <div class="col-lg-12 text-center" v-if='(showErrorStatus || showInputErrorStatus) && ! isLoadingBlade'>
            <base-alert :type="errorType">
                <!-- <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span> -->
                <span class="alert-inner--text">
                    <h2 class="text-white">{{errorMsg}}</h2>
                </span>
            </base-alert>
        </div>

        <div class="col-lg-12" v-if="! isLoadingBlade">
            <base-button
                type="success"
                class="right"
                @click="savePersonalData()"
            >
                {{ isloading ? 'Please Wait...' : 'Save &amp; Continue'}}
                <i class="fa fa-arrow-right"></i>
            </base-button>
        </div>

    </div>
</template>

<script>
    import VuePhoneNumberInput from 'vue-phone-number-input';
    import 'vue-phone-number-input/dist/vue-phone-number-input.css';
    import { mapActions, mapGetters } from 'vuex';
    import swal from 'sweetalert2';// import sweet alert
    import moment from 'moment';
    import Autocomplete from 'vuejs-auto-complete';

    export default {
        name: 'ProfileData',
        props: ['displayPhoto', 'data', 'loadCpdInfo', 'id', 'lock'],
        components: {
            VuePhoneNumberInput,
            Autocomplete
        },
        data() {
            return {
                isLoadingBlade: true,
                isloading: false,
                showInputErrorStatus: false,
                showErrorStatus: false,
                errorType: null,
                errorMsg: null,
                record: {},
                accreditation: [],
                state: {},
                tel: {
                    phone: null,
                    code: 'NG',
                }
            }
        },
        computed: {
            ...mapGetters(['loadingBladeUrl', 'locations']),
            years() {
                let startYear = moment().format('YYYY') - 122;// start from 1900
                let currentYear = parseInt(moment().format('YYYY'));
                let years = [];
                for (let i = currentYear; i > startYear; i-- ) {
                    years.push(i);
                }
                return years;
            }
        },
        methods: {
            ...mapActions(['updateCpdData', 'getLocations']),
            setProfileData() {// set applicant data
                this.showInputErrorStatus = false;
                this.emptyFields();
                this.isLoadingBlade = false;
                if(this.data) {
                    let application = this.data;
                    let cpd = application.cpd;
                    this.record = {
                        name: cpd.name,
                        email: cpd.email,
                        phone: cpd.phone,
                        phone_code: cpd.phone_code,
                        location: cpd.location,
                        website: cpd.website,
                        category: cpd.category,
                        cac: cpd.cac,
                        state_id: cpd.state_id,
                        experience: cpd.experience
                    };
                    this.state = cpd.state;
                    this.setAccreditationData(application.accreditation_type);
                    this.loadPhoneNumber();
                }
            },
            setAccreditationData(data) {// set accreditation data
                if(data) {
                    let records = JSON.parse(data);
                    for(let record of records) {
                        this.accreditation.filter(function(item) {
                            if(item.name == record) {
                                return item.status = true;
                            }
                        });
                    }
                }
            },
            loadPhoneNumber() {// update phone no
                let data = this.record.phone_code;
                let exploded = data ? data.split('-') : false;
                this.tel.code = exploded && exploded[0] ? exploded[0] : 'NG';
                this.tel.phone = exploded && exploded[1] ? exploded[1] : null;
            },
            savePersonalData() {
                this.isloading = true;
                this.showInputErrorStatus = false;
                let accreditation = this.formatAccreditation(this.accreditation);// format accreditation
                this.preloader();// show loading
                let status = this.validatedData();// valid input data
                if(status && accreditation && accreditation.length > 0) {// update
                    this.record.accreditation_type = JSON.stringify(accreditation);// add accreditation type
                    let data = { profile_data : this.record, id : this.id };
                    this.updateCpdData(data).then((res) => {
                        this.isloading = false;
                        this.loadCpdInfo();
                        if(res.status) {
                            this.displayPhoto(true);
                            swal.fire("Profile Updated!", res.msg, "success");
                        } else {
                            let msg = "Error occurred while updating, reload page";
                            swal.fire("Error Ocurred!", msg, "warning");
                        }
                    }).
                    catch(() => {
                        this.isloading = false;
                        let msg = "Network Failure!, kindly reload the page...";
                        swal.fire("Error Ocurred!", msg, "warning");
                    });
                } else if(status && accreditation.length == 0) {
                    let msg = 'Select at least one Accreditation Type';
                    this.showInuptError(msg);
                    swal.close();
                } else {
                    this.isloading = false;
                    swal.close();
                }
            },
            formatAccreditation(accreditation) {
                let items = Object.entries(accreditation);
                let array = [];
                for(let item of items) {
                    let log = item.length > 0 ? item[1] : false;
                    if(log && log.status) { array.push(log.name); }
                }
                return array;
            },
            emptyFields() {
                this.record = {
                    name: null,
                    email: null,
                    phone: null,
                    phone_code: null,
                    location: null,
                    website: null,
                    category: null,
                    cac: null,
                    experience: null,
                    state_id: null
                };
                return this.accreditation = [
                    { name: "Basic Medical Sciences", status: false },
                    { name: "Medical/Dental Subspecialty", status: false },
                    { name: "Administration/Management", status: false },
                    { name: "Information and Communication Technology", status: false },
                    { name: "Law/Medico-legal", status: false },
                    { name: "E-learning", status: false },
                    { name: "Others", status: false }
                ];
            },
            validatedData() {
                let data = this.record;
                data.category != null ? data.category : this.showInuptError('Category of Organization');
                data.location != null ? data.location : this.showInuptError('Office Address');
                data.phone != null ? data.phone : this.showInuptError('Phone Number');
                data.name != null ? data.name : this.showInuptError('Institution\'s Name');
                let status = data.name && data.phone && data.location && data.category;
                return status ? true : false;
            },
            showInuptError(name) {
                this.isloading = false;
                this.showInputErrorStatus = true;
                this.errorType = 'warning'
                this.errorMsg = 'Invalid ' + name + ' has been entered';
                var self = this;
                setTimeout(function() { self.showInputErrorStatus = false; }, 5000);
            },
            updatePhoneNumber(event) {
                if(event && event.isValid && event.e164) {
                    this.record.phone = event.formattedNumber;
                    this.record.phone_code = event.countryCode+'-'+event.phoneNumber;
                }
            },
            preloader() {
                return  swal.fire({
                    title: 'Please Wait...',
                    html: 'Trying to fetch data from <b>server</b>',
                    // timer: 2000,
                    timerProgressBar: true,
                    onBeforeOpen: () => {
                        swal.showLoading()
                    },
                });
            }
        },
        created() {
            this.setProfileData();
            this.getLocations();
        }
    }
</script>

<style scoped>
    .no-padding-bottom {
        padding-bottom: 0rem !important
    }
    .right {
        float: right !important;
    }
</style>

<style>
    .form-control-label {
        width: 100%;
    }
    .height-50 {
        height: 50px;
    }
    .borderLine {
        border: 1px solid #ededed;
    }
</style>