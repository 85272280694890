<template>
    <div>
        <base-header type="gradient-danger" class="pb-6 pb-8 pt-5 pt-md-8">
            <!-- Card stats -->



            <div class="row" v-if="! isLoadingBlade && cpd">
                <div class="col-lg-12" v-if="visitation.status && ! cpd.visit_pay_status">
                  <router-link :to="visitation.url">
                    <marquee-text :duration="30" class="text-white">
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      Note: {{ visitation.text }}
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </marquee-text>
                  </router-link>
                  <br><br>
                </div>

                <div class="col-xl-4 col-lg-6">
                    <stats-card title="Directors Count"
                                type="gradient-danger"
                                :sub-title="cpd.directors_count.toString()"
                                icon="ni ni-circle-08"
                                class="mb-4 mb-xl-0 dark"
                    >
                      <template slot="footer">
                        <span class="text-success mr-2"></span>
                        <span class="text-nowrap"></span>
                      </template>
                    </stats-card>
                </div>

                <div class="col-xl-4 col-lg-6">
                    <stats-card title="Consultants Count"
                                type="gradient-danger"
                                :sub-title="cpd.consultants_count.toString()"
                                icon="ni ni-satisfied"
                                class="mb-4 mb-xl-0 yellow"
                    >
                      <template slot="footer">
                        <span class="text-success mr-2"></span>
                        <span class="text-nowrap"></span>
                      </template>
                    </stats-card>
                </div>

                <div class="col-xl-4 col-lg-6" v-if="cpd.visit_pay_status">
                    <stats-card title="CPD Provider Type"
                                type="gradient-danger"
                                :sub-title="providerType"
                                icon="ni ni-satisfied"
                                class="mb-4 mb-xl-0 red text-uppercase"
                    >
                      <template slot="footer">
                        <span class="text-success mr-2"></span>
                        <span class="text-nowrap"></span>
                      </template>
                    </stats-card>
                </div>

                <div class="col-xl-4 col-lg-6" v-if="! cpd.visit_pay_status">
                    <stats-card title="Visitation Status"
                                type="gradient-danger"
                                :sub-title="visitation.title"
                                icon="ni ni-support-16"
                                class="mb-4 mb-xl-0 "
                                :class="visitation.color"
                    >
                      <template slot="footer">
                            <span class="text-success mr-2">
                              <i class="fa fa-arrow-right"></i> Next Action:</span>
                            <span class="text-nowrap">
                              <router-link
                                  :to="visitation.url"
                                  class="text-dark text-capitalize"
                              >
                                {{ visitation.button }}
                              </router-link>
                            </span>
                        </template>
                    </stats-card>
                </div>
            </div>
        </base-header>

        <!--Charts-->
        <div class="container-fluid mt--7 padding30">
            <div class="row">
                <div class="col-xl-12 order-xl-2 mb-5 mb-xl-0">

                    <div class="card card-profile shadow">

                        <div class="col-lg-12" v-if="isLoadingBlade">
                            <div class="d-flex justify-content-center">
                                <img :src="loadingBladeUrl" />
                            </div>
                        </div>

                        <div v-if="! isLoadingBlade && cpd">
                            <div class="row justify-content-center">
                              <div class="col-lg-3 order-lg-2">
                                  <div class="card-profile-image">
                                      <router-link to="/profile">
                                          <img
                                            :alt="cpd.name"
                                            :src="photo"
                                            @error="replaceByDefault"
                                            class="rounded-circle image"
                                          >
                                      </router-link>
                                  </div>
                              </div>
                          </div>
                          <div class="card-header text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4">
                              <div class="d-flex justify-content-between">
                                  <a
                                    href="/user-guide.pdf" 
                                    target="_blank"
                                    class="mr-4 btn btn-sm btn-primary"
                                  >
                                    User Guide
                                  </a>
                                  <router-link
                                    to="/"
                                    class="mr-4 btn btn-sm btn-default float-right text-uppercase"
                                  >
                                    CPD Provider ({{ providerType }})
                                  </router-link>
                              </div>
                          </div>
                          <div class="card-body pt-0 pt-md-4" v-if="cpd">
                              <div class="row">
                                  <div class="col">
                                      <div class="card-profile-stats d-flex justify-content-center mt-md-5">
                                      </div>
                                  </div>
                              </div>
                              <div class="text-center">
                                  <h2 class="text-capitalize">
                                      {{ cpd.name }}
                                  </h2>
                                  <h3 class="text-capitalize">
                                      <badge size="sm"
                                        :type="cpd.approved_status == 'approved' ? 'success' : 'warning'"
                                      >
                                        {{ cpd.approved_status }}
                                      </badge>
                                  </h3>
                                  <div class="h5 mt-4 fontSize15">
                                      <i class="ni business_briefcase-24 mr-2"></i>
                                        Email Address
                                        <badge size="sm" type="success" class="text-lowercase">
                                          {{ cpd.email }}
                                        </badge>
                                  </div>
                                  <div class="h5 mt-4 fontSize15">
                                      <i class="ni business_briefcase-24 mr-2"></i>
                                        Phone Number
                                        <badge size="sm" type="success" class="text-lowercase">
                                          {{ cpd.phone }}
                                        </badge>
                                  </div>
                                  <div class="h5 mt-4 fontSize15">
                                      <i class="ni business_briefcase-24 mr-2"></i>
                                        Category
                                        <badge size="sm" type="success" class="text-capitalize">
                                          {{ cpd.category }}
                                        </badge>
                                  </div>
                                  <div class="h5 mt-4 fontSize15">
                                      <i class="ni business_briefcase-24 mr-2"></i>
                                        Location
                                        <badge size="sm" type="success" class="text-lowercase">
                                          {{ cpd.location }}
                                        </badge>
                                  </div>
                              </div>
                          </div>

                          <!-- Other Notice template here -->
                          <div class="col-md-4" v-if="visitModel">
                            <modal :show="visitModel"
                                  gradient="danger"
                                  modal-classes="modal-danger modal-dialog-centered">
                                <h4
                                  slot="header" class="modal-title"
                                  id="modal-title-notification"
                                >
                                  Importance Notice
                                </h4>

                                <div class="py-3 text-center">
                                    <i class="ni ni-bell-55 ni-3x"></i>
                                    <h4 class="heading mt-4">Pay Visitation Fee Now!</h4>
                                    <p>
                                      <strong>Note:</strong> Your Application has been approved,
                                      kindly click the button below to pay the required visitation fee now...
                                    </p>
                                </div>

                                <template slot="footer">
                                  <router-link
                                    to="/visitation-payment"
                                    class="mr-4 btn btn-lg btn-white"
                                  >
                                    Pay Now
                                  </router-link>
                                    <base-button type="link"
                                                text-color="white"
                                                class="ml-auto"
                                                @click="visitModel = false">
                                        Close
                                    </base-button>
                                </template>
                            </modal>
                          </div>

                          <!-- Reject template here -->
                          <div class="col-md-4" v-if="rejectModal">
                            <modal :show="rejectModal"
                                  gradient="danger"
                                  modal-classes="modal-danger modal-dialog-centered">
                                <h4
                                  slot="header" class="modal-title"
                                  id="modal-title-notification"
                                >
                                  Importance Notice
                                </h4>

                                <div class="py-3 text-center">
                                    <i class="ni ni-bell-55 ni-3x"></i>
                                    <h4 class="heading mt-4">Application Rejected!</h4>
                                    <p>
                                      <strong>Note:</strong> Your Application was rejected,
                                      kindly click the button below to make corrections and re-submit...
                                    </p>
                                </div>

                                <template slot="footer">
                                  <router-link
                                    :to="'/edit-application/'+ rejectId"
                                    class="mr-4 btn btn-lg btn-white"
                                    v-if="rejectId"
                                  >
                                    Click Here
                                  </router-link>

                                  
                                    <base-button type="link"
                                                text-color="white"
                                                class="ml-auto"
                                                @click="rejectModal = false">
                                        Close
                                    </base-button>
                                </template>
                            </modal>
                          </div>

                      </div>
                    </div>
                </div>
            </div>
            <!-- End charts-->
        </div>
    </div>
</template>
<script>
  // get user's details from store
  import { mapGetters, mapActions } from 'vuex';
  import moment from 'moment';
  import MarqueeText from 'vue-marquee-text-component';

  export default {
    name: 'Dashboard',
    components: { MarqueeText },
    filters: {
        getDateFormat(value) {
            if(value) {
              return moment(value).format('Do MMM, YYYY');
            } else {
              return 'N/A';
            }
        }
    },
    computed: {
      ...mapGetters(['cpd', 'loadingBladeUrl', 'blankImageUrl', 'cpdPhotoUrl']),
      visitation() {
        if(this.cpd && this.cpd.reject_status) {
          return {
            status : true, color : 'red', title : 'Application Rejected',
            button : 'View Now', url : ('edit-application/' + this.cpd.reject_status),
            text : 'Your Application was rejected, kindly click here to view applications'
          };
        } else {
          let status = this.cpd && this.cpd.visitation_status;
          return {
            status : status,
            color : (status ? 'green' : 'red'),
            title : (status ? 'Pay Visitation Fee' : 'Pending Approval'),
            button : (status ? 'Pay Now' : 'Still Wait'),
            url : (status ? '/visitation-payment' : '/'),
            text : 'Your Application has been approved, kindly click here to pay the required visitation fee'
          };
        }
      },
      photo() {// get MD Passport
        return this.cpd && this.cpd.photo_url ? this.cpd.photo_url : this.blankImageUrl;
      },
      rejectId() {
        return this.cpd.reject_status || this.cpd.reject_visit_status; 
      },
      providerType() {
        return this.cpd && this.cpd.provider_type ? this.cpd.provider_type : '';
      }
    },
    data() {
      return {
        modal: false,
        isLoadingBlade: true,
        visitModel: false,
        rejectModal: false
      };
    },
    methods: {
      ...mapActions(['fetchCpdProfile']),
      stopLoading() {
        var self = this;
        if(this.cpd) {
          setTimeout(function() {
            self.visitModel = (self.cpd.visitation_status && ! self.cpd.visit_pay_status) ? true : false;
            self.rejectModal = (self.cpd.reject_status || self.cpd.reject_visit_status) ? true : false;
            self.isLoadingBlade = false;
          }, 1000);
        }
      },
      replaceString(value) {
          if(value) {
            return value.replace("-", " ");
          } else {
            return 'N/A';
          }
      },
      replaceByDefault(e) {
          return e.target.src = this.blankImageUrl;
      }
    },
    mounted() {
      this.fetchCpdProfile();// get profile
      this.stopLoading();
    }
  };
</script>
<style scoped>
  .padding30 {
    padding: 30px;
  }
  .fontSize15 {
    font-size: 15px;
  }
  .red {
    background-color: #efd2d2;
  }
  .green {
    background-color: #bcf9c6;
  }
  .yellow {
    background-color: #e8eb4b;
  }
  .left {
    float: left;
  }
  .image {
    width: 170px;
    height: 170px;
  }
</style>

<style>
  .modal-footer {
    margin-top: -50px !important;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    padding: 1.25rem;
    border-top: 0 solid #e9ecef;
    border-bottom-right-radius: 0.4375rem;
    border-bottom-left-radius: 0.4375rem;
  }
</style>
