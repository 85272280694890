<template>
    <div>
        <div class="pl-lg-4">
            <div class="row">
                <div class="col-lg-12" v-if="isLoadingBlade">
                    <div class="d-flex justify-content-center">
                        <img :src="loadingBladeUrl" />
                    </div>
                </div>

                <div class="col-lg-12" v-if="! isLoadingBlade">
                    <div class="card shadow">
                        <div class="table-responsive">
                            <base-table
                                class="table align-items-center table-flush"
                                thead-classes="thead-light"
                                tbody-classes="list"
                                :data="applications"
                                v-if="applications && applications.length > 0"
                            >
                                <template slot="columns">
                                    <th width="2%">S/N</th>
                                    <th>Type</th>
                                    <th>Application<br> Date</th>
                                    <th>Status</th>
                                    <th>Payment<br> Status</th>
                                    <th>Approve/<br>Reject Date</th>
                                    <th width="5%">&nbsp;</th>
                                </template>

                                <template
                                    slot-scope="{row, index}"
                                >
                                <td class="budget">
                                    {{ pagination.from+index}}
                                </td>
                                <td class="text-capitalize">
                                    {{ row.application_type && row.application_type.name 
                                        ? row.application_type.name : 'N/A' }}
                                </td>
                                <td>
                                    {{ row.application_date | getDateFormat }}
                                </td>
                                <td>
                                    <badge :type="getStatusColor(row.status)">
                                        {{ row.status }}
                                    </badge>
                                </td>
                                <td>
                                    <badge :type="getPaymentStatusColor(row.payment_status)">
                                        {{ row.payment_status }}
                                    </badge>
                                </td>
                                <td>
                                    {{ row.approved_date | getDateFormat }}
                                </td>
                                <td>
                                    <router-link
                                        :to="'/application-details/'+row.id"
                                        class="btn btn-sm btn-success"
                                    >
                                        <i class="fa fa-eye"></i> Details
                                    </router-link>

                                    <!-- <router-link
                                        :to="'/edit-application/'+row.id"
                                        class="btn btn-sm btn-danger"
                                        v-if="row.status == 'rejected'"
                                    >
                                        <i class="fa fa-pen"></i> Edit Application
                                    </router-link> -->
                                </td>

                                </template>

                            </base-table>
                        </div>

                        <div class="col-lg-12 text-center" v-if='showErrorStatus && ! isLoadingBlade'>
                            <base-alert :type="errorType">
                                <!-- <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span> -->
                                <span class="alert-inner--text">
                                    <h2 class="text-white">{{errorMsg}}</h2>
                                </span>
                            </base-alert>
                        </div>

                        <div
                            class="card-footer d-flex justify-content-end bg-transparent"
                            v-if="hasPagination"
                            @click="paginate"
                        >
                            <base-pagination
                                :total="pagination.total"
                                align="center"
                                v-model="pagination.number"
                                :perPage="pagination.perPage"

                            >
                            </base-pagination>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import { mapActions, mapGetters } from 'vuex';
    import Badge from '../../components/Badge.vue';
    import moment from 'moment';

    export default {
        components: { Badge },
        name: 'CpdApplications',
        props: ['type'],
        data() {
            return {
                applications: [],
                paginatedItems: [],
                pagination: {
                    total: null,
                    prev_page_url: null,
                    next_page_url: null,
                    current_page: null,
                    last_page: null,
                    number: 1,
                    from: 0,
                    to: null,
                    perPage: this.$store.state.user.perPage // get per page count
                },
                showErrorStatus: false,
                errorType: null,
                errorMsg: null,
                isloading: false,
                isLoadingBlade: true,
                procedureModal: true
            }
        },
        filters: {
            getDateFormat(value) {
                if (value) {
                    return moment(value).format('Do MMM, YYYY');
                } else {
                    return 'N/A';
                }
            }
        },
        computed: {
            ...mapGetters(['loadingBladeUrl']),
            hasPagination() {
                return this.pagination.next_page_url || this.pagination.prev_page_url;
            }
        }, 
        methods: {
            ...mapActions(['getCpdApplications', 'getPaginatedApplicationItems']),
            loadApplications() {
                const cpdId = this.$store.state.user.cpdId;
                let data = { id : cpdId, limit : this.pagination.perPage, code : this.type };
                this.getCpdApplications(data).then((res) => {// get applications
                    this.setPages(res.applications);// set pagination
                    this.isLoadingBlade = false;
                }).
                catch(() => {
                    this.showErrorStatus = true;
                    this.errorMsg = "Network Failure!", "Kindly reload the page...";
                    this.errorType = 'danger';
                });
            },
            setPages(res) {// set paginated function
                this.applications = [];
                this.paginatedItems = res.data;
                this.applications = this.paginatedItems;
                this.pagination.from = res.from;
                this.pagination.to = res.to;
                this.pagination.total = res.total;
                this.pagination.current_page = res.current_page;
                this.pagination.last_page = res.last_page;
                this.pagination.prev_page_url = res.prev_page_url;
                this.pagination.next_page_url = res.next_page_url;
                this.isLoadingBlade = false;
                this.emptyRecordsMsg();//show empty msg
            },
            paginate() {// paginate function
                this.showErrorStatus = false;
                let url = (this.pagination.number > this.pagination.current_page) ?
                    this.pagination.next_page_url : this.pagination.prev_page_url;
                if(url) {// make api call
                    this.isLoadingBlade = true
                    this.getPaginatedApplicationItems(url).then((res) => {// get users
                        this.pagination.number = res.current_page;
                        this.setPages(res);
                    }).
                    catch(() => {
                        this.users = [];
                        this.showErrorStatus = true;
                        this.isLoadingBlade = false;
                        this.errorMsg = "Network Failure!, Kindly reload the page...";
                        this.errorType = 'danger';
                    });
                } else {
                    this.applications = this.paginatedItems;
                }
            },
            emptyRecordsMsg() {
                return this.applications && this.applications.length == 0
                    ? this.showEmptyMsg() : false;
            },
            showEmptyMsg() {// show empty msg
                this.showErrorStatus = true;
                this.errorType = 'warning';
                this.errorMsg = 'No Applications Yet!, kindly apply for one';
            },
            getStatusColor(status) {
                if(status == 'approved') {
                    return 'success';
                } else if(status == 'pending' || status == 'submitted') {
                    return 'warning';
                } else {
                    return 'danger';
                }
            },
            getPaymentStatusColor(status) {
                if(status == 'paid') {
                    return 'success';
                } else {
                    return 'danger';
                }
            }
        },
        created() {
            this.loadApplications();
        }
    };
</script>
<style scoped>
    .width600 {
        width: 600px !important;
    }
    .marginLeft23 {
        margin-left: 23px;
    }
    .image-size {
        height: 180px;
        width: 190px;
    }
</style>
