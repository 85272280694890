<template>
    <div>
        <base-header class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center no-padding-bottom">
            <span class="mask bg-gradient-danger opacity-8"></span>
        </base-header>

        <div class="container-fluid mt--7">

            <div class="row">
                <div class="col-xl-12 order-xl-1">
                    <br><br><br>
                    <card shadow type="secondary">
                        <div v-if="! isSubmitted">
                                <div slot="header" class="bg-white border-0">
                                <div class="row align-items-center">

                                    <div class="col-8">
                                        <h3 class="mb-0 text-uppercase">
                                            {{ (isReAccreditation ? 'Re-Accreditation ' : '') + this.$route.name }}
                                        </h3>
                                        <br>
                                        <span class="text-danger">
                                            Below are all information supplied earlier...
                                        </span>
                                    </div>
                                    <div class="col-4 text-right">
                                        <router-link
                                            to="/visitation-payment"
                                            class="btn btn-danger"
                                        >
                                            <i class="fa fa-arrow-left"></i>
                                            Back
                                        </router-link>
                                    </div>
                                </div>
                            </div>

                            <div v-if="! isLoadingBlade && ! showErrorStatus">

                                <!-- This is profile details template here -->
                                <ProfileDetails
                                    :application="application"
                                    v-if="application"
                                />

                                <!-- This is directors details template here -->
                                <DirectorDetails
                                    :records="application.cpd.directors"
                                    v-if="application && application.cpd && application.cpd.directors"
                                />

                                <!-- This is staff details template here -->
                                <StaffDetails
                                    :records="application.cpd.staff"
                                    v-if="application && application.cpd && application.cpd.staff"
                                />

                                <!-- This is consultant details template here -->
                                <ConsultantDetails
                                    :records="application.cpd.consultants"
                                    :documentUrl="documentUrl"
                                    v-if="application && application.cpd && application.cpd.consultants"
                                />

                                <!-- this is cpd's documents -->
                                <Documents
                                    :records="application.documents"
                                    v-if="application && application.documents"
                                />

                            </div>

                            <div class="col-lg-12">
                                <h3 class="mb-4 text-danger">
                                    Kindly hit the submit button, so MDCN can review your visitation application...
                                </h3>
                                <hr>
                            </div>


                            <div class="col-lg-12" v-if="isLoadingBlade">
                                <div class="d-flex justify-content-center">
                                    <img :src="loadingBladeUrl" />
                                </div>
                            </div>

                            <div class="col-lg-12 text-center" v-if='showErrorStatus && ! isLoadingBlade'>
                                <base-alert :type="errorType">
                                    <!-- <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span> -->
                                    <span class="alert-inner--text">
                                        <h2 class="text-white">{{errorMsg}}</h2>
                                    </span>
                                </base-alert>
                            </div>

                            <div class="col-lg-12" v-if="! isLoadingBlade">
                                <router-link
                                    to="/visitation-payment"
                                    class="btn btn-danger"
                                >
                                    <i class="fa fa-arrow-left"></i>
                                    Previous
                                </router-link>

                                <base-button
                                    type="success"
                                    class="right"
                                    @click="submitNow()"
                                >
                                    <i class="fa fa-save"></i>
                                    {{ isloading ? 'Please Wait...' : 'Submit Now' }}
                                </base-button>
                            </div>
                        </div>

                        <div class="col-lg-12" v-if="isSubmitted">
                            <div class="d-flex justify-content-center">
                                <img :src="completeImageUrl" />
                            </div>
                            <br>
                            <h2 class="text-uppercase text-center">
                                Your Visitation application was submitted succesfully... 
                            </h2>
                            <h4 class="text-uppercase text-center text-danger">
                                Note: You will be contacted subsequently by MDCN, when the date of visitation is fixed
                            </h4>
                            <hr>
                            <div class="text-center">
                                <router-link
                                    to="/"
                                    class="btn btn-lg btn-success"
                                >
                                    <i class="fa fa-home"></i>
                                    Dashboard Now
                                </router-link>
                            </div>
                            <br><br>
                        </div>

                    </card>
                </div>
            </div>
            
        </div>
    </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex';
    import ProfileDetails from '../../Verification/Application/VerificationSummary/ProfileDetails';
    import ConsultantDetails from '../../Verification/Application/VerificationSummary/ConsultantDetails';
    import DirectorDetails from '../../Verification/Application/VerificationSummary/DirectorDetails';
    import StaffDetails from '../../Verification/Application/VerificationSummary/StaffDetails';
    import Documents from '../../Verification/Application/VerificationSummary/Documents';
    import swal from 'sweetalert2';// import sweet alert

    export default {
        name: 'VisitationPreview',
        components: {
            ProfileDetails,
            ConsultantDetails,
            DirectorDetails,
            StaffDetails,
            Documents
        },
        data() {
            return {
                application: null,
                isLoadingBlade: false,
                isloading: false,
                showErrorStatus: false,
                errorType: null,
                errorMsg: null,
                isSubmitted: false
            }
        },
        computed: {
            ...mapGetters(['loadingBladeUrl', 'completeImageUrl', 'cpd', 'getRootUrl']),
            isReAccreditation() {// check if cpd is applying for re-accreditation
                return this.cpd && (this.cpd.approved_status == 'approved');
            },
            documentUrl() {// get document url
                return this.getRootUrl+'/storage/cpd/cpd'+this.cpd.id;
            }
        },
        methods: {
            ...mapActions(['submitCpdApplication', 'getPendingVisitApplication']),
            loadCpdVisitInfo() {
                this.isLoadingBlade = true;
                this.application = null;
                this.getPendingVisitApplication().then((res) => {
                    this.isLoadingBlade = false;
                    if(res.status) {
                        this.application = res.application;
                        if(! res.application) {
                            return window.location.href = '/';
                        }
                    } else {
                        let msg = 'Payment has not been made, proceed to make payment'
                        swal.fire("Payment Failed!", msg, "warning");
                        setTimeout(function() { window.location.href = '/visitation-payment'; }, 3000);
                    }
                }).
                catch(() => {
                    this.showErrorStatus = true;
                    this.isLoadingBlade = false;
                    this.errorMsg = "Network Failure!, kindly reload the page...";
                    this.errorType = 'danger';
                });
            },
            submitNow() {// submit application
                swal.fire({
                    title: 'Are you sure?',
                    text: "You won't be able to revert this!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, Proceed!'
                }).then((result) => {
                    if (result.value) {
                        this.makeSubmitApiCall();// make api call
                    }
                });
            },
            makeSubmitApiCall() {
                this.isloading = true;
                this.showInputErrorStatus = false;
                this.preloader();// show loading
                if(this.application.id) {// submit
                    this.submitCpdApplication(this.application.id).then((res) => {
                        this.isloading = false;
                        if(res.status) {
                            swal.fire("Application Submitted!", res.msg, "success");
                            this.isSubmitted = true;
                            // setTimeout(function() { window.location.href = '/applications'; }, 1000); 330641368475
                        } else {
                            let msg = "An error occurred while submitting form, reload page";
                            swal.fire("Error Ocurred!", msg, "warning");
                        }
                    }).
                    catch(() => {
                        this.isloading = false;
                        let msg = "Network Failure!, kindly reload the page...";
                        swal.fire("Error Ocurred!", msg, "warning");
                    });
                }   else {
                    this.isloading = false;
                    let msg = "Network Failure!, kindly reload the page...";
                    swal.fire("Error Ocurred!", msg, "warning");
                }
            },
            preloader() {
                return  swal.fire({
                    title: 'Please Wait...',
                    html: 'Trying to fetch data from <b>server</b>',
                    // timer: 2000,
                    timerProgressBar: true,
                    onBeforeOpen: () => {
                        swal.showLoading()
                    },
                });
            }
        },
        created() {
            this.loadCpdVisitInfo();
        }
    }
</script>

<style scoped>
    .no-padding-bottom {
        padding-bottom: 0rem !important
    }
    .right {
        float: right !important;
    }
</style>

<style>
    .form-control-label {
        width: 100%;
    }
    .height-50 {
        height: 50px;
    }
    .borderLine {
        border: 1px solid #ededed;
    }
</style>