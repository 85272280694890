<template>
    <div class="row" v-if="! showAddRecord && consultantRecords && consultantRecords.length > 0">
        <div class="col-lg-12">
            <div class="card shadow">
                <div class="table-responsive">
                    <base-table
                        class="table align-items-center table-flush"
                        thead-classes="thead-light"
                        tbody-classes="list"
                        :data="consultantRecords"
                    >
                        <template slot="columns">
                            <th width="2%">S/N</th>
                            <th>Surname</th>
                            <th>First Name</th>
                            <th>Other Name / <br> Previous Name</th>
                            <th>Gender</th>
                            <th>Designation <br> / Department</th>
                            <th>Supporting Credentials <br> / Practicing Licence</th>
                            <th width="5%">&nbsp;</th>
                        </template>

                        <template
                            slot-scope="{row, index}"
                        >
                        <td class="budget">
                            {{ pagination.from+index+1}}
                        </td>
                        <td class="text-capitalize">
                            {{ row.surname ? row.surname : 'N/A' }}
                        </td>
                        <td class="text-capitalize">
                            {{ row.first_name ? row.first_name : 'N/A' }}
                        </td>
                        <td class="text-capitalize">
                            {{ row.other_name ? row.other_name : 'N/A' }}<br>
                            <badge type="primary">
                                {{ row.previous_name ? row.previous_name : 'N/A' }}
                            </badge>
                        </td>
                        <td class="text-capitalize">
                            {{ row.gender }}
                        </td>
                        <td>
                            {{ row.designation ? row.designation : 'N/A' }} <br>
                            <badge type="success">
                                {{ row.department ? row.department : 'N/A' }}
                            </badge>
                        </td>
                        <td>
                            <a
                                :href="documentUrl + '/consultant'+ row.id +'/credentials.pdf'"
                                class="btn btn-sm btn-info"
                                target="_blank"
                                v-if="row.credential_file"
                            >
                                <i class="fa fa-eye"></i> View Credentials
                            </a>
                            <badge
                                type="info" class="text-danger"
                                v-if="! row.credential_file"
                            >
                                No Credentials Uploaded
                            </badge>
                            <br>
                            <a
                                :href="documentUrl + '/consultant'+ row.id +'/license.pdf'"
                                target="_blank"
                                class="btn btn-sm btn-primary marginTop5"
                                v-if="row.license_file"
                            >
                                <i class="fa fa-eye"></i> View License
                            </a>
                            <badge
                                type="primary" class="marginTop5 text-danger"
                                v-if="! row.license_file"
                            >
                                No License Uploaded
                            </badge>
                        </td>
                        <td>
                            <base-button
                                type="info" size="sm"
                                @click="$emit('editRecord', row)"
                                :disabled="lock"
                            >
                                <i class="fa fa-pen"></i> Edit
                            </base-button>
                            <!-- <base-button
                                type="primary" size="sm"
                                @click="$emit('addQualification', row, true)"
                            >
                                <i class="fa fa-plus"></i> Qualifications
                            </base-button> -->
                            <base-button
                                type="danger" size="sm"
                                @click="$emit('deleteRecord', row.id)"
                                :disabled="lock"
                            >
                                <i class="fa fa-trash"></i> Remove
                            </base-button>
                        </td>
                        </template>

                    </base-table>
                </div>

                <div
                    class="card-footer d-flex justify-content-end bg-transparent"
                    v-if="hasPagination"
                     @click="paginate"
                >
                    <base-pagination
                        :total="pagination.total"
                        align="center"
                        v-model="pagination.number"
                        :perPage="pagination.perPage"

                    >
                    </base-pagination>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'ConsultantRecords',
        props: ["consultantRecords", "showAddRecord", "pagination", "hasPagination", "paginate", "documentUrl", "lock"]
    }
</script>

<style scoped>
    .marginTop5 {
        margin-top: 5px;
    }
</style>