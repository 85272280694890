<template>
    <div>
        <base-header class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center no-padding-bottom">
            <span class="mask bg-gradient-danger opacity-8"></span>
        </base-header>

        <div class="container-fluid mt--7">
            <div class="row">
                <div class="col-xl-12 order-xl-1">
                    <br><br><br>
                    <card shadow type="secondary">
                        <div slot="header" class="bg-white border-0">
                            <div class="row align-items-center">

                                <div class="col-8">
                                    <h3 class="mb-0 text-uppercase">
                                        {{ isReAccreditation ? 'RE-Accreditation '+ this.$route.name : this.$route.name }}
                                    </h3>
                                    <br>
                                    <span class="text-danger">
                                        Kindly note that your application must have been approved before proceeding
                                        to make payment...
                                    </span>
                                </div>
                                <div class="col-4 text-right">
                                    <router-link
                                        to="/"
                                        class="btn btn-sm btn-primary"
                                    >
                                        <i class="ni ni-tv-2 text-white"></i>
                                        Dashboard
                                    </router-link>
                                </div>
                            </div>
                        </div>


                        <div class="row" v-if="isLoadingBlade">
                            <div class="col-lg-12">
                                <div class="d-flex justify-content-center">
                                    <img :src="loadingBladeUrl" />
                                </div>
                            </div>
                        </div>

                        <div class="row" v-if='cpd && cpd.visitation_status && ! isLoadingBlade'>
                            <div class="col-sm-6 text-center borderLine">
                                <br>
                                <h4 class="text-uppercase height-50">
                                    Payment for 
                                    {{ (isReAccreditation ? 'RE-Accreditation ': '') + 'Visitation'}}<br>
                                    <em class="text-danger">
                                        I do not have RRR and I have not made payment
                                    </em>
                                </h4>
                                <router-link
                                    :to="notPaidUrl"
                                    class="btn btn-sm btn-primary"

                                >
                                    Click &amp; Continue
                                    <i class="fa fa-arrow-right"></i>
                                </router-link>
                                <br><br><br>
                            </div>

                            <div class="col-sm-6 text-center borderLine">
                                <br>
                                <h4 class="text-uppercase height-50">
                                    Proceed after 
                                    {{ (isReAccreditation ? 'RE-Accreditation ': '') + 'Visitation'}} payment<br>
                                    <em class="text-danger">
                                        I have RRR and have made payment, I want to continue
                                        application
                                    </em>
                                </h4>
                                <router-link
                                    :to="paidUrl"
                                    class="btn btn-sm btn-success"
                                >
                                    Click &amp; Continue
                                    <i class="fa fa-arrow-right"></i>
                                </router-link>
                                <br><br><br>
                            </div>
                        </div>

                        <div class="row" v-if='cpd && ! cpd.visitation_status && ! isLoadingBlade'>
                            <div class="col-lg-12 text-center" >
                                <base-alert type="warning">
                                    <!-- <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span> -->
                                    <span class="alert-inner--text">
                                        <h2 class="text-white">
                                            Your Application is still pending, kindly wait for approval
                                        </h2>
                                    </span>
                                </base-alert>
                            </div>
                        </div>
                    </card>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';

    export default {
        name: 'Visitation',
        props: ['type'],
        data() {
            return {
                isLoadingBlade: true,
                showErrorStatus: false,
                errorType: null,
                errorMsg: null
            }
        },
        computed: {
            ...mapGetters(['loadingBladeUrl', 'cpd']),
            isReAccreditation() {// check if cpd is applying for re-accreditation
                return this.cpd && (this.cpd.approved_status == 'approved');
            },
            notPaidUrl() {// not paid visitation url 
                return this.isReAccreditation ? '/re-visitation-generate' : '/visitation-generate';
            },
            paidUrl() {// not paid visitation url 
                return this.isReAccreditation ? '/re-visitation/check-payment' : '/visitation/check-payment';
            }
        },
        methods: {
            ...mapActions(['fetchCpdProfile']),
            stopLoading() {
                var self = this;
                if(this.cpd) {
                    setTimeout(function() { self.isLoadingBlade = false; }, 1000);
                }
            }
        },
        created() {
            this.fetchCpdProfile();
            this.stopLoading();
        }
    }
</script>

<style scoped>
    .no-padding-bottom {
        padding-bottom: 0rem !important
    }
    .right {
        float: right;
    }
</style>

<style>
    .form-control-label {
        width: 100%;
    }
    .height-50 {
        height: 70px;
    }
    .borderLine {
        border: 1px solid #ededed;
    }
</style>