<template>
    <div class="pl-lg-4">

        <div class="col-lg-12" v-if="isLoadingBlade">
            <div class="d-flex justify-content-center">
                <img :src="loadingBladeUrl" />
            </div>
        </div>

        <div v-if="! isLoadingBlade && ! showQualPage">
            <h3 class="text-uppercase">Consultants / Doctors Information</h3>

            <em class="mb-4 text-danger">
                Kindly add personnels below...
            </em>

            <hr class="my-4"/>

            <!-- import institution details template here -->
            <ConsultantRecords
                :consultantRecords="consultantRecords"
                :showAddRecord="showAddRecord"
                :documentUrl="documentUrl"
                v-on:editRecord="editRecord"
                v-on:deleteRecord="deleteRecord"
                v-on:addQualification="addQualification"
                :pagination="pagination"
                :hasPagination="hasPagination"
                :paginate="paginate"
                :lock="lock"
            />

            <hr class="my-4" v-if="hasRecords && ! showAddRecord"/>

            <div class="pl-lg-4" v-if="hasRecords && ! showAddRecord">
                <base-button
                    type="primary"
                    @click="displayAddPage()"
                    :disabled="lock"
                >
                    <i class="fa fa-plus"></i>
                    Add New Record
                </base-button>
            </div>


            <div class="row align-items-center" v-if="! hasRecords || showAddRecord">

                <div class="col-lg-3">
                    <base-input alternative=""
                        label="Surname"
                        placeholder="Enter Surname"
                        input-classes="form-control-alternative"
                        v-model="record.surname"
                        required
                        :readonly="lock"
                    />
                </div>

                <div class="col-lg-3">
                    <base-input alternative=""
                        label="First Name"
                        placeholder="Enter First Name"
                        input-classes="form-control-alternative"
                        v-model="record.first_name"
                        required
                        :readonly="lock"
                    />
                </div>

                <div class="col-lg-3">
                    <base-input alternative=""
                        label="Other Names"
                        placeholder="Enter Other Names"
                        input-classes="form-control-alternative"
                        v-model="record.other_name"
                        :readonly="lock"
                    />
                </div>

                <div class="col-lg-3">
                    <base-input alternative=""
                        label="Previous Name"
                        placeholder="Enter Previous Name"
                        input-classes="form-control-alternative"
                        v-model="record.previous_name"
                        :readonly="lock"
                    />
                </div>

                <div class="col-lg-4">
                    <base-input alternative=""
                        label="Gender"
                        required
                    >
                        <select
                            class="form-control form-control-alternative"
                            v-model="record.gender"
                            :disabled="lock"
                        >
                            <option :value="null" disabled selected>--- Select One ---</option>
                            <option value="male">Male</option>
                            <option value="female">Female</option>
                        </select>
                    </base-input>
                </div>

                <div class="col-lg-4">
                    <base-input alternative=""
                        label="Designation"
                        placeholder="Enter Designation"
                        input-classes="form-control-alternative"
                        v-model="record.designation"
                        :readonly="lock"
                    />
                </div>

                <div class="col-lg-4">
                    <base-input alternative=""
                        label="Department"
                        placeholder="Enter Department"
                        input-classes="form-control-alternative"
                        v-model="record.department"
                        :readonly="lock"
                    />
                </div>

                <div class="col-lg-12"><hr></div>

                <div class="col-lg-12">
                    <h6 class="heading-small text-muted mb-4">
                        Upload Files &nbsp;&nbsp;&nbsp;&nbsp;
                        <span class="text-danger">
                            <i class="fa fa-info-circle"></i> Only PDF Files (Less than 2MB) are supported
                        </span>
                    </h6>
                </div>

                <div
                    class="col-lg-6 text-center borderLine"
                    v-for="(document, index) in documents"
                    :key="index"
                >
                    <br>
                    <input
                        type="file"
                        id="file" style="display: none"
                        @change="onFileSelected"
                    >
                    <h4 class="height-50" v-html="document.name"></h4><br><br>
                    <base-button
                        size="sm" type="primary"
                        v-if="! selectedFileName && (document.filename =='credentials.pdf')"
                        @click="selectFile(document.filename)"
                        :disabled="record.credential_file && record.credential_file.name"
                    >
                        <i class="fa fa-hand-pointer"></i>
                        {{ selectedCredential ? 'Change File' : 'Select File' }}
                    </base-button>

                    <base-button
                        tag="a" size="sm"
                        type="success"
                        :href="documentUrl+ '/consultant'+ record.id+'/'+document.filename"
                        target="_blank"
                        role="button"
                        aria-pressed="true"
                        v-if="selectedCredential && (document.filename =='credentials.pdf') && record.id"
                    >
                        <i class="fa fa-eye"></i> View File
                    </base-button>

                    <base-button
                        size="sm" type="primary"
                        v-if="! selectedFileName && (document.filename =='license.pdf')"
                        @click="selectFile(document.filename)"
                        :disabled="record.license_file && record.license_file.name"
                    >
                        <i class="fa fa-hand-pointer"></i>
                        {{ selectedLicense ? 'Change File' : 'Select File' }}
                    </base-button>

                    <base-button
                        tag="a" size="sm"
                        type="success"
                        :href="documentUrl+ '/consultant'+ record.id+'/'+document.filename"
                        target="_blank"
                        role="button"
                        aria-pressed="true"
                        v-if="selectedLicense && (document.filename =='license.pdf') && record.id"
                    >
                        <i class="fa fa-eye"></i> View File
                    </base-button>

                    <base-button
                        size="sm" type="danger"
                        v-if="(record.credential_file.name == document.filename || record.license_file.name == document.filename)
                            && (record.credential_file.name || record.license_file.name)"
                        @click="removeFile(document.filename)"
                    >
                        <i class="fa fa-times"></i> Remove File
                    </base-button>
                    <br><br>
                </div>

                <div class="col-lg-12" v-if='showInputErrorStatus'>
                    <hr>
                    <base-alert :type="errorType" dismissible>
                        <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                        <span class="alert-inner--text">
                            {{errorMsg}}
                        </span>
                        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </base-alert>
                </div>

                <div class="col-lg-12"><hr></div>

                <div class="col-lg-12">
                    <base-button
                        type="success"
                        @click="saveDoctorRecord()"
                        :disabled="lock"
                    >
                        <i class="fa fa-save"></i>
                        {{isloading ? 'Please Wait...' : updateButton }}
                    </base-button>

                    <div class="right" v-if="hasRecords">
                        <base-button
                            type="danger"
                            @click="displayAddPage()"
                        >
                            <i class="fa fa-times"></i>
                            Cancel
                        </base-button>
                    </div>
                </div>

            </div>

            <hr class="my-4"/>

            <div class="pl-lg-4">
                <base-button
                    type="danger"
                    @click="showPreviousPage()"
                >
                    <i class="fa fa-arrow-left"></i>
                    Previous
                </base-button>
                <div class="right" v-if="! editForm">
                    <base-button
                        type="success"
                        @click="showNextPage()"
                        :disabled="! enableNextButton"
                    >
                        Continue
                        <i class="fa fa-arrow-right"></i>
                    </base-button>
                </div>
            </div>
        </div>

        <!-- <Qualifications
            :id="id"
            :records="consultant"
            :type="'consultant'"
            :title="'Consultant / Doctor Information'"
            v-on:addQualification="addQualification"
            :loadCpdInfo="loadCpdInfo"
            v-if="showQualPage && consultant"
        /> -->

    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import ConsultantRecords from './ConsultantRecords/ConsultantRecords';
    // import Qualifications from './Qualifications/Qualifications';
    import swal from 'sweetalert2';// import sweet alert

    export default {
        components: {
            ConsultantRecords,
            // Qualifications
        },
        name: 'ConsultantData',
        props: ['loadCpdInfo', 'application', 'id', 'displayStaffData', 'displayDocuments', 'lock', 'editForm'],
        data() {
            return {
                consultantRecords: [],
                paginatedItems: [],
                record: {
                    surname: null,
                    first_name: null,
                    other_name: null,
                    previous_name: null,
                    gender: null,
                    designation: null,
                    department: null,
                    credential_file: {
                        name : null,
                        file : null
                    },
                    license_file: {
                        name : null,
                        file : null
                    },
                },
                pagination: {
                    total: null,
                    number: 1,
                    from: null,
                    perPage: this.$store.state.user.perPage // get per page count
                },
                showErrorStatus: false,
                errorType: null,
                errorMsg: null,
                isloading: false,
                showInputErrorStatus: false,
                isLoadingBlade: true,
                showAddRecord: false,
                config: {
                    allowInput: true,
                    altInput: true,// eslint-disable-next-line
                    plugins: [new monthSelectPlugin({shorthand: true, dateFormat: "Y-m-d", altFormat: "M Y"})]
                },
                showQualPage: false,
                consultant: [],
                selectedCredential: false,
                selectedLicense: false,
                selectedFileName: null,
                newRecord: false
            };
        },
        computed: {
            ...mapGetters(['loadingBladeUrl', 'getRootUrl']),
            documents() {
                return [
                    {
                        'name' : 'Credentials <br> <em class="text-danger">'+
                                'Note: Merge all credentials (MBBS Degree Certificate, Full Certificate,  <br>'+
                                'and All Additional Qualification / Specialist Certificate) as one document <br>(required)</em>',
                        'filename' : 'credentials.pdf'
                    },
                    {
                        'name' : 'Current Practicing License <br> <em class="text-danger">(required)</em>',
                        'filename' : 'license.pdf'
                    }
                ];
            },
            documentUrl() {// get document url
                return this.getRootUrl+'/storage/cpd/cpd'+this.application.cpd_id;
            },
            hasPagination() {
                return this.paginatedItems && (this.paginatedItems.length > this.pagination.perPage)
                    ? true : false;
            },
            hasRecords() {
                return this.consultantRecords && this.consultantRecords.length > 0;
            },
            enableNextButton() {
                return this.consultantRecords && this.consultantRecords.length > 0;
            },
            updateButton() {
                return (this.record.id ? 'Update' : 'Add') + ' Record';
            }
        },
        methods: {
            ...mapActions(['updateCpdData', 'deleteCpdData']),
            displayAddPage() {
                this.emptyRecords();
                this.showAddRecord = ! this.showAddRecord;
                return this.newRecord = this.showAddRecord
            },
            editRecord(data) {// edit record
                if(data) {
                    this.record = {
                        surname: data.surname,
                        first_name: data.first_name,
                        other_name: data.other_name,
                        previous_name: data.previous_name,
                        gender: data.gender,
                        designation: data.designation,
                        department: data.department,
                        id: data.id,
                        credential_file: {
                            name : null,
                            file : null
                        },
                        license_file: {
                            name : null,
                            file : null
                        }
                    };
                    this.selectedCredential = data.credential_file;
                    this.selectedLicense = data.license_file;
                    this.showAddRecord = true;
                }
            },
            loadConsultantRecords() {
                this.isLoadingBlade = false;
                if(this.id) {
                    this.setData(this.application);
                } else {
                    swal.fire("Session Expired!", "Session timeout, try again...", "warning");
                    window.location.href = '/';
                }
            },
            setData(application) {// set paginated function
                if(application && application.cpd) {
                    let res = application.cpd.consultants;
                    this.consultantRecords = [];
                    this.paginatedItems = res;
                    this.pagination.total = this.paginatedItems.length; // get items total
                    let perPage = this.pagination.perPage; // get per page count
                    if(this.paginatedItems.length > perPage) {// paginate
                        let numberOfPages = Math.ceil(this.paginatedItems.length / perPage);
                        for (let index = 1; index <= numberOfPages; index++) {
                            this.consultantRecords.push(this.paginatedItems[index]);
                        }
                    } else {
                        this.consultantRecords = this.paginatedItems;
                    }
                }
            },
            paginate() {// paginate function
                this.consultantRecords = [];
                this.pagination.from = null;
                let page = this.pagination.number;
                let perPage = this.pagination.perPage;
                let from = (page * perPage) - perPage;
                let to = (page * perPage);
                this.pagination.from = from;//get first item number
                return this.consultantRecords = this.paginatedItems.slice(from, to);
            },
            showPreviousPage() {// go to next page
                return this.displayStaffData(true);
            },
            showNextPage() {// go to next page
                return this.displayDocuments(true);
            },
            saveDoctorRecord() {
                this.isloading = true;
                let data = this.validatedInputs();// validate inputs
                if(data) {// save
                    this.preloader();// show loading
                    this.record.cpd_id = this.application.cpd.id;
                    let record = { consultant_data : this.record , id : this.id };
                    this.updateCpdData(record).then((res) => {
                        this.loadCpdInfo();
                        this.isloading = false;
                        if(res.status) {
                            this.showErrorStatus = false;
                            this.showAddRecord = false;
                            swal.fire("Record Added!", res.msg , "success");
                            this.loadConsultantRecords();// load appointments
                            this.emptyRecords();
                        } else {
                            swal.fire("Error Occurred!", "An error occurred, kindly reload page and try again", "warning");
                        }
                    }).
                    catch(() => {
                        this.isloading = false;
                        this.emptyRecords();
                        swal.fire("Error Occurred!", "An error occurred, kindly reload page and try again", "warning");
                    });
                } else {
                    this.isloading = false;
                }
            },
            showErrorMsg() {
                this.showInputErrorStatus = true
                this.errorType = 'warning'
                this.errorMsg = 'An error occurred, kindly reload page and try again';
                var self = this;
                setTimeout(function() { self.showInputErrorStatus = false; }, 5000);
            },
            validatedInputs() {
                let data = this.record;
                let appRecord = { surname: null, first_name: null, gender: null, credential_file: null, license_file: null };
                appRecord.license_file = ! data.license_file.file && ! this.selectedLicense ?
                                this.showInuptError('License File') : true;
                appRecord.credential_file = ! data.credential_file.file && ! this.selectedCredential ?
                                this.showInuptError('Credentials File') : true;
                appRecord.gender = data.gender == null ? this.showInuptError('Gender') : data.gender;
                appRecord.first_name = data.first_name == null ? this.showInuptError('First Name') : data.first_name;
                appRecord.surname = data.surname == null ? this.showInuptError('Surname') : data.surname;
                return this.isEmpty(appRecord) ? appRecord : false;
            },
            isEmpty(obj) {// check for empty field
                return ! Object.keys(obj).some(k => !obj[k]);
            },
            showInuptError(name) {
                this.isloading = false;
                this.showInputErrorStatus = true
                this.errorType = 'warning'
                this.errorMsg = 'Invalid ' + name + ' has been entered';
                var self = this;
                setTimeout(function() { self.showInputErrorStatus = false; }, 5000);
            },
            emptyRecords() {
                this.selectedCredential = false;
                this.selectedLicense = false;
                this.selectedFileName = null;
                this.newRecord = false;
                return this.record = {
                    surname: null,
                    first_name: null,
                    other_name: null,
                    previous_name: null,
                    gender: null,
                    designation: null,
                    department: null,
                    credential_file: {
                        name : null,
                        file : null
                    },
                    license_file: {
                        name : null,
                        file : null
                    }
                };
            },
            deleteRecord(id) {// delete examination
                this.preloader();// show loading
                let data = { consultant_data : true , id : id };
                this.deleteCpdData(data).then((res) => {
                    this.loadCpdInfo();
                    if(res.status) {
                        swal.fire("Record Deleted!", res.msg , "success");
                        this.loadConsultantRecords(); // load appointments
                    } else {
                        swal.fire("Error Occurred!", "An error occurred while deleting, try again", "warning");
                        this.loadConsultantRecords(); // load appointments
                    }
                }).
                catch(() => {
                    swal.fire("Network Failure", "Reload page and try again...", "warning");
                    this.loadConsultantRecords(); // load appointments
                });
            },
            addQualification(data, status) {
                this.consultant = [];
                if(status) {
                    this.consultant.push(data);
                    this.showQualPage = status;
                } else {
                    this.showQualPage = status;
                }
            },
            selectFile(name) {// eslint-disable-next-line
                $('#file').val('');
                this.selectedFileName = null;// eslint-disable-next-line
                $('#file').click();
                this.selectedFileName = name;
                if(name == 'credentials.pdf') {
                    this.record.credential_file.name = name;
                } else if(name == 'license.pdf') {
                    this.record.license_file.name = name;
                }
            },
            onFileSelected(event) {
                let status =  this.validateFile(event.target.files[0]);
                if(status) {
                    var fileReader = new FileReader();
                    fileReader.readAsDataURL(event.target.files[0]);
                    fileReader.onload = (event) => {// convert file to binary
                        var file = event.target.result;
                        if(this.selectedFileName == 'credentials.pdf') {
                            this.record.credential_file.file = file;
                            this.selectedFileName = null;
                        } else if(this.selectedFileName == 'license.pdf') {
                            this.record.license_file.file = file;
                            this.selectedFileName = null;
                        }
                    }
                }
                return status;
            },
            validateFile(file) {// check for valid file
                if(!file) {// update seleted file name
                    if(this.selectedFileName == 'credentials.pdf') {
                        this.record.credential_file.name = null
                    } else if(this.selectedFileName == 'license.pdf') {
                        this.record.license_file.name = null;
                    }
                }
                var fileExt = file.type.split('/').pop();
                let fileSize = file.size;
                if(fileExt == 'pdf' && fileSize <= 2000000) {// for valid file and size
                    if(this.selectedFileName == 'credentials.pdf') {
                        // this.selectedCredential = true
                    } else if(this.selectedFileName == 'license.pdf') {
                        // this.selectedLicense = true;
                    }
                    return true;
                } else if(fileExt != 'pdf') {// invalid file
                    this.removeAllFiles();
                    swal.fire("Invalid File!", "Only PDF files are accepted", "warning");
                    return false;
                } else if(fileSize > 2000000) {// big file
                    this.removeAllFiles();
                    swal.fire("File is too large!", "File should be less than 2MB", "warning");
                    return false;
                }
            },
            removeFile(name) {// eslint-disable-next-line
                $('#file').val('');
                if(name == 'credentials.pdf') {
                    this.record.credential_file.name = null;
                    this.record.credential_file.file = null;
                    this.selectedCredential = false;
                } else if(name == 'license.pdf') {
                    this.record.license_file.name = null;
                    this.record.license_file.file = null;
                    this.selectedLicense = false;
                }
            },
            removeAllFiles() {// eslint-disable-next-line
                $('#file').val('');
                this.record.credential_file.name = null;
                this.record.license_file.name = null;
                this.selectedFileName = null;
            },
            preloader() {
                return  swal.fire({
                    title: 'Please Wait...',
                    html: 'Trying to fetch data from <b>server</b>',
                    // timer: 2000,
                    timerProgressBar: true,
                    onBeforeOpen: () => {
                        swal.showLoading()
                    },
                });
            }
        },
        created() {
            this.loadConsultantRecords();// load appointments
        },
    }
</script>

<style>
.right {
    float: right;
}
</style>

<style>
    .form-control-label {
        width: 100%;
    }
</style>